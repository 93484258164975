"use client";

import { Box } from "@mui/material";

import ToyotaCertificateHomeCard from "@/components/Card/ToyotaCertificateHomeCard";
import CarouselNavigateFraction from "@/components/CarouselCommon/CarouselNavigateFraction";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { toyotaCertificateListMock } from "@/libs/mocks/toyota-certificate.mock";
import color from "@/styles/color";

const Highlights = () => {
  const { isLessThanSm } = useReadScreen();
  return (
    <>
      {isLessThanSm ? (
        <CarouselNavigateFraction
          name="home_certificate_action"
          containerSx={{
            position: "relative",
            maxWidth: { xs: "100%", xl: "100%" },
            "& .swiper-slide": {
              width: { xs: "unset", md: "unset", xl: "100%" },
              height: "auto",
            },
            "& .carousel_navigate_fraction_main": {
              "> .swiper-wrapper": {
                marginBottom: { xs: "12px", sm: "unset" },
              },
            },
          }}
          navigateType={["nvg-bullets"]}
          swiperOption={{
            breakpoints: {
              0: {
                slidesPerView: 2,
                spaceBetween: 16,
                slidesPerGroup: 2,
              },
            },
          }}
          options={{
            bulletNonActiveColor: color.gray170,
            prevBtnSx: {
              top: "35%",
            },
            nextBtnSx: {
              top: "35%",
            },
            bulletsContainerSx: {
              padding: { xs: "8px 0px", sm: "24px 0px" },
            },
            nvgBtnWrap: true,
          }}
        >
          {toyotaCertificateListMock.map((item, index) => (
            <ToyotaCertificateHomeCard key={`certificate-item-${index}`} data={item} />
          ))}
        </CarouselNavigateFraction>
      ) : (
        <Box
          sx={{
            display: "grid",
            gap: 3,
            gridTemplateColumns: { sm: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" },
          }}
        >
          {toyotaCertificateListMock.map((item, index) => (
            <ToyotaCertificateHomeCard key={`certificate-item-${index}`} data={item} />
          ))}
        </Box>
      )}
    </>
  );
};

export default Highlights;
