"use client";
import CarouselCommon from "@/components/CarouselCommon/CarouselCommon";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import { Box, Container } from "@mui/material";
import React, { useMemo } from "react";
import Image from "next/image";
import { useQuery } from "@tanstack/react-query";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import color from "@/styles/color";

const ProductBanner = () => {
  const { isLessThanSm } = useReadScreen();
  const params = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(ContentManagementQueryKeyEnum.ABTTSURE, {
      Length: 1,
      Limit: 6,
      ImageType: isLessThanSm ? "Thumbnail Mobile" : "Thumbnail",
    });
    return params;
  }, [isLessThanSm]);

  const { data } = useQuery<ContentManagementListResponse>({
    queryKey: [HomeQueryKeyEnum.HOME_PRD_CONTENT, isLessThanSm],
    queryFn: () => ContentManagementServices.getContentManagement(params).then((res) => res),
    enabled: !!params,
  });

  const productBannerList = useMemo(() => {
    return data?.ContentManagement?.filter((item) => item?.ImageFileURL) ?? [];
  }, [data]);

  return (
    <Box
      sx={{
        background: color.white,
        width: "100%",
        height: "100%",
      }}
    >
      <Container
        maxWidth="2xl"
        sx={{
          width: "100%",
          padding: "0 !important",
        }}
      >
        <Box
          sx={{
            padding: {
              xs: "24px",
              md: "40px",
              xl: "40px 100px",
            },
            position: "relative",
            width: "100%",
            height: {
              xs: "236px",
              md: "309px",
              lg: "403px",
              xl: "437px",
            },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
            }}
          >
            <CarouselCommon>
              {productBannerList.map(({ ContentId, ImageFileURL, Title }, index) => {
                return (
                  <Box
                    key={`product-banner-home-${ContentId}`}
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      aspectRatio: {
                        xs: 750 / 197,
                      },
                      img: {
                        width: "100%",
                        aspectRatio: {
                          xs: 750 / 197,
                        },
                      },
                    }}
                  >
                    <Image
                      src={ImageFileURL ?? ""}
                      alt={Title ?? "Toyota Sure Banner"}
                      fill
                      objectFit="cover"
                      priority={index <= 0 ? true : false}
                      loading={index <= 0 ? undefined : "lazy"}
                    />
                  </Box>
                );
              })}
            </CarouselCommon>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ProductBanner;
