import ImageCustom from "@/components/Image/ImageCustom";
import color from "@/styles/color";
import LineLogoImg from "@/assets/image/LINE_logo.webp";
import MessengerLogoImg from "@/assets/image/messenger-logo.webp";
import IconLoader from "@/components/IconLoader";

export const containerSX = {
  ".panigate": {
    color: color.white,
  },
  position: "relative",
  maxWidth: { xs: "100%", xl: "100%" },
  height: "auto",
  "& .swiper-slide": {
    width: { xs: "unset", md: "unset", xl: "100%" },
  },
  "& .carousel_navigate_fraction_main": {
    "> .swiper-wrapper": {
      marginBottom: { xs: "12px", sm: "unset" },
    },
  },
  display: "grid",
  flexDirection: "column",
  rowGap: { xs: "16px", sm: "24px" },
  columns: {
    xs: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
};

export const swiperOption = {
  slidesPerView: 2,
  spaceBetween: 20,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    834: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1194: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
};

export const DetailAndServiceTaps: { id: number; title: string; icon: JSX.Element }[] = [
  {
    id: 1,
    title: "common.contact.messenger",
    icon: (
      <ImageCustom
        imageSrc={MessengerLogoImg.src}
        imageAlt="Messenger Logo"
        sx={{
          img: {
            objectFit: "contain",
            maxWidth: "unset",
            width: {
              xs: "1.5rem !important",
            },
            height: {
              xs: "1.5rem !important",
            },
          },
        }}
        options={{
          sizes: "10vw",
        }}
      />
    ),
  },
  {
    id: 2,
    title: "common.contact.phone-mobile",
    icon: (
      <IconLoader iconName="TelephoneIcon" sx={{ fontSize: { xs: "1.25rem", xl: "1.8rem" } }} />
    ),
  },
  {
    id: 3,
    title: "common.contact.line",
    icon: (
      <ImageCustom
        imageSrc={LineLogoImg.src}
        imageAlt="Messenger Logo"
        sx={{
          img: {
            objectFit: "contain",
            maxWidth: "unset",
            width: {
              xs: "1.5rem !important",
            },
            height: {
              xs: "1.5rem !important",
            },
            borderRadius: "100%",
          },
        }}
        options={{
          sizes: "10vw",
        }}
      />
    ),
  },
  {
    id: 4,
    title: "common.contact.website",
    icon: (
      <IconLoader
        iconName="SocialGradientIcon"
        sx={{ fontSize: { xs: "1.25rem", xl: "1.8rem" }, color: color.red110 }}
      />
    ),
  },
  {
    id: 5,
    title: "common.contact.email",
    icon: (
      <IconLoader
        iconName="EmailGradientIcon"
        sx={{
          fontSize: {
            xs: "1.25rem",
            xl: "1.8rem",
          },
          color: color.red110,
        }}
      />
    ),
  },
];
