import { IToyotaCertificateItem } from "../types/toyota-certificate.type";

import CarCrashImg from "@/assets/image/cert/car-crash.webp";
import CarRepairImg from "@/assets/image/cert/car-repair.webp";
import CertificateImg from "@/assets/image/cert/certificate.webp";
import ShieldCarImg from "@/assets/image/cert/shield-car.webp";

export const toyotaCertificateListMock: IToyotaCertificateItem[] = [
  {
    id: 1,
    image: CarCrashImg.src,
    title: "การันตี ไม่จมน้ำ\nไม่ชนหนัก ไม่กรอไมล์",
    description: "",
    link: "/",
  },
  {
    id: 2,
    image: CertificateImg.src,
    title: "ผ่านการตรวจสภาพ\nตามมาตรฐาน TVI จากประเทศญี่ปุ่น\nครอบคลุม 280 รายการ",
    description: "",
    link: "/",
  },
  {
    id: 3,
    image: ShieldCarImg.src,
    title: "รถ Certified 5 ปี\nเพิ่มระยะรับประกันต่อจากรถใหม่อีก 1 ปี\nหรือ 20,000 กิโล",
    description: "",
    link: "/",
  },
  {
    id: 4,
    image: CarRepairImg.src,
    title:
      "รถ Certified 12 ปี หรือ Certified Gold สามารถเลือกแพ็จเกจการรับประกันเพิ่มได้สูงสุด 2 ปี หรือ 40,000 กิโล",
    description: "",
    link: "/",
  },
];
