"use client";

import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";

import { SearchCarSchema } from "@/components/SearchCars/SearchCars";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { useWantToBuyStore } from "@/contexts/useWantToBuy";
import { PagePath } from "@/libs/constant/path";
import { MasterData } from "@/libs/types/master-data.type";
import { ViewFilter } from "@/libs/types/want-to-buy.type";
import { usePageLoadingStore } from "@/contexts/usePageLoading";
import dynamic from "next/dynamic";

const SearchCars = dynamic(() => import("@/components/SearchCars/SearchCars"));

const HomeSearchCar = () => {
  const router = useRouter();
  const searchForm = useForm<SearchCarSchema>({
    defaultValues: {},
  });
  const { generateUrlSlug, setViewFilter } = useWantToBuyStore();
  const { setLoading } = usePageLoadingStore();

  const onSubmitSearch = searchForm.handleSubmit(async () => {
    try {
      const formValues = searchForm.getValues();
      setViewFilter({
        Province: formValues.province ? ({ Name: formValues.province } as MasterData) : undefined,
        CarType: formValues.carType ? ({ Name: formValues.carType } as MasterData) : undefined,
        Brand: formValues.brand ? ({ Name: formValues.brand } as MasterData) : undefined,
        Model: formValues.model ? ({ Name: formValues.model } as MasterData) : undefined,
        Price:
          formValues.priceStart && formValues.priceEnd
            ? ({
                Min: formValues.priceStart ? +formValues.priceStart : 0,
                Max: formValues.priceEnd ? +formValues.priceEnd : 0,
              } as FilterMinMax)
            : undefined,
        Year:
          formValues.yearStart && formValues.yearEnd
            ? ({
                Min: formValues.yearStart ? +formValues.yearStart : 0,
                Max: formValues.yearEnd ? +formValues.yearEnd : 0,
              } as FilterMinMax)
            : undefined,
      } as ViewFilter);
      const urlSlug = generateUrlSlug();
      setLoading();
      router.push(`${PagePath.wantToBuy}/${urlSlug}`, { scroll: false });
    } catch (error) {
      console.log("onSubmitSearch error => ", error);
    } finally {
      await onResetSearch();
    }
  });

  const onResetSearch = () => {
    searchForm.setValue("brand", undefined);
    searchForm.setValue("carType", undefined);
    searchForm.setValue("model", undefined);
    searchForm.setValue("priceStart", undefined);
    searchForm.setValue("priceEnd", undefined);
    searchForm.setValue("province", undefined);
    searchForm.setValue("searchText", undefined);
    searchForm.setValue("yearStart", undefined);
    searchForm.setValue("yearEnd", undefined);
  };

  return (
    <Box>
      <RHFFormProvider
        methods={searchForm}
        onSubmit={onSubmitSearch}
        onReset={onResetSearch}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <SearchCars form={searchForm} />
      </RHFFormProvider>
    </Box>
  );
};

export default HomeSearchCar;
