"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import ButtonCustom from "@/components/Button/ButtonCustom";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useTranslation } from "@/i18n/client";
import { VehicleServices } from "@/libs/services/vehicle";
import { VehicleSearchParams, VehicleSearchReponse } from "@/libs/types/vehicle.type";
import color from "@/styles/color";
import VehicleSuggestionCard from "@/components/Card/VehicleSuggestionCard";
import { HomeQueryKeyEnum, SuggestVehicleSortEnum } from "@/libs/enum/home-query-key.enum";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { PagePath } from "@/libs/constant/path";
import Link from "next/link";
import { ICarouselNavigateFractionRefProps } from "@/components/CarouselCommon/CarouselNavigateFraction";
import { useWishlist } from "@/contexts/useWishlist";
import SkeletonSuggestVehicle from "@/components/Skeleton/SkeletonSuggestVehicle";
import RenderCarouselSuggest from "./components/CarSuggestionCarousel";
import { VehicleSearchSortByEnum } from "@/libs/enum/wtb-query-key.enum";
import { getCookie } from "cookies-next";
import { VehicleViewedHistory } from "@/libs/constant/key-localstorage.constant";

type SuggestSort = {
  key: SuggestVehicleSortEnum;
  params: {
    IsCertified?: boolean;
    SortBy: VehicleSearchSortByEnum;
  };
};

const SuggestionCar = () => {
  const { t } = useTranslation();
  const { setVehicleByID, getVehiclesDict } = useWishlist();
  const vehicleDict = getVehiclesDict();
  const { isLessThanSm } = useReadScreen();
  const [activeSort, setActiveSort] = useState<SuggestVehicleSortEnum>(SuggestVehicleSortEnum.New);
  const [enabledQuery, setEnabledQuery] = useState<boolean>(false);

  const carouselNvgSuggestFristRef = React.useRef<ICarouselNavigateFractionRefProps>(null);
  const carouselNvgSuggestSecondRef = React.useRef<ICarouselNavigateFractionRefProps>(null);

  const [params, setParams] = useState<VehicleSearchParams>({
    Limit: isLessThanSm ? 8 : 16,
    SortBy: VehicleSearchSortByEnum.NewArrival,
    SortDirection: "DESC" as SortDirection,
    StartIndex: 0,
    IsCertified: true,
  });

  const vehicleViewedHistory = getCookie(VehicleViewedHistory);

  const buttonSortList = useMemo(() => {
    const vehicleMasterId = vehicleViewedHistory?.split(",")[0];
    return [
      {
        key: SuggestVehicleSortEnum.New,
        params: {
          IsCertified: false,
          SortBy: VehicleSearchSortByEnum.NewArrival,
        },
      },
      {
        key: SuggestVehicleSortEnum.Popular,
        params: {
          IsCertified: false,
          SortBy: VehicleSearchSortByEnum.ViewCount,
        },
      },
      {
        key: SuggestVehicleSortEnum.Suggestion,
        params: {
          SortBy: VehicleSearchSortByEnum.SuggestCars,
          SortDirection: "ASC" as SortDirection,
          ...(vehicleMasterId ? { VehicleMasterId: vehicleMasterId } : {}),
        },
      },
    ] as SuggestSort[];
  }, [vehicleViewedHistory]);

  const {
    data: vehicleList,
    isFetching,
    refetch,
  } = useQuery<VehicleSearchReponse>({
    queryKey: [HomeQueryKeyEnum.HOME_SUGGEST_VEHICLE, activeSort],
    queryFn: () => {
      return VehicleServices.getVihicleSearch(params);
    },
    placeholderData: keepPreviousData,
  });

  const onChangeActiveSort = useCallback(
    (sort: SuggestSort) => {
      setActiveSort(sort.key);
      setParams({
        ...params,
        ...sort.params,
      });
      setEnabledQuery(true);
    },
    [params, setActiveSort, setParams],
  );

  useEffect(() => {
    if (enabledQuery) {
      refetch();
    }
  }, [enabledQuery, refetch]);

  const vehicleSlide = useMemo(() => {
    const lengthPerSlide = 8;
    if (!isLessThanSm && vehicleList?.SearchResult) {
      const firstSlide = vehicleList?.SearchResult.slice(0, lengthPerSlide);
      const secondSlide = vehicleList?.SearchResult.slice(lengthPerSlide);
      return [firstSlide, secondSlide];
    }
    return null;
  }, [vehicleList, isLessThanSm]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: 2, sm: 3 } }}>
        <Stack alignItems="center" flexDirection="row" gap={1} justifyContent="center">
          <Typography
            sx={{
              color: color.black,
              fontWeight: 600,
              fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
              lineHeight: { xs: "18px", sm: "24px", lg: "30px" },
            }}
          >
            {t("Home.suggest-car.sort.label")}
          </Typography>
          <Stack
            alignItems="center"
            flexDirection="row"
            gap={1}
            sx={{ flex: { xs: 1, sm: "unset" } }}
          >
            {buttonSortList.map((item) => {
              return (
                <ButtonCustom
                  key={`button-sort-by-${item.key}`}
                  label={t(`Home.suggest-car.sort.${item.key}`)}
                  onClick={() => onChangeActiveSort(item)}
                  sx={{
                    height: { xs: "40px", lg: "56px" },
                    fontWeight: 500,
                    fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
                    lineHeight: { xs: "18px", sm: "24px", lg: "30px" },
                    transition: "all 0.3s ease",
                    width: { xs: "100%", sm: "108px", lg: "fit-content" },
                    padding: { xs: "16px 8px", sm: "16px 32px" },
                    ...(activeSort === item.key
                      ? {
                          background: `${color.redGradient} !important`,
                          color: `${color.white} !important`,
                        }
                      : {
                          background: `${color.gray220} !important`,
                          color: `${color.gray400} !important`,
                        }),
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
        {isLessThanSm ? (
          <Grid
            container
            columnSpacing={{ xs: "8px" }}
            rowSpacing={{
              xs: "16px",
            }}
            columns={{
              xs: 8,
            }}
          >
            {isFetching
              ? Array.from({ length: 8 }).map((_, index: number) => (
                  <Grid item xs={4} key={`wtb-car-skeleton-${index + 1}`}>
                    <Skeleton
                      variant="rectangular"
                      animation={"wave"}
                      sx={{
                        width: "100%",
                        height: { xs: "500px !important" },
                        borderRadius: "16px",
                      }}
                    />
                  </Grid>
                ))
              : vehicleList?.SearchResult?.map((vehicle) => {
                  return (
                    <Grid item key={`vehicle-card-${vehicle.VehicleMasterId}`} xs={4}>
                      <VehicleSuggestionCard
                        data={vehicle}
                        isWishlist={vehicleDict[vehicle.VehicleMasterId]}
                        onClickWishlist={setVehicleByID}
                      />
                    </Grid>
                  );
                })}
          </Grid>
        ) : (
          <>
            {isFetching ? (
              <Stack sx={{ gap: { sm: 3, lg: 5 } }}>
                <SkeletonSuggestVehicle itemCount={4} />
                <SkeletonSuggestVehicle itemCount={4} />
              </Stack>
            ) : vehicleSlide ? (
              <Stack sx={{ gap: { sm: 3, lg: 5 } }}>
                {vehicleSlide[0] && vehicleSlide[0].length > 0 && (
                  <RenderCarouselSuggest
                    ref={carouselNvgSuggestFristRef}
                    items={vehicleSlide[0]}
                    actionName="home-car-suggestion-top"
                    carouselName={"carousel_suggest-car-1"}
                  />
                )}

                {vehicleSlide[1] && vehicleSlide[1].length > 0 && (
                  <RenderCarouselSuggest
                    ref={carouselNvgSuggestSecondRef}
                    items={vehicleSlide[1]}
                    actionName="home-car-suggestion-bottom"
                    carouselName={"carousel_suggest-car-2"}
                  />
                )}
              </Stack>
            ) : null}
          </>
        )}
      </Box>
      <ButtonOutlinedCustom
        component={Link}
        label={t("Home.suggest-car.view-all")}
        sx={{
          background: color.white,
          margin: "0 auto",
          paddingX: 0,
          height: { xs: "48px", sm: "56px" },
          width: { xs: "137px", sm: "203px" },
          textTransform: "inherit",
        }}
        href={PagePath.wantToBuy}
        endIcon={
          <Box
            sx={{
              width: { xs: "20px" },
              height: { xs: "20px" },
              background: color.redGradient,
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForwardIosRoundedIcon sx={{ color: color.white, fontSize: "12px" }} />
          </Box>
        }
      />
    </>
  );
};

export default SuggestionCar;
