"use client";

import { useMemo } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import TestimonialCard from "@/components/Card/TestimonialCard";
import CarouselNavigateFraction from "@/components/CarouselCommon/CarouselNavigateFraction";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { useTranslation } from "@/i18n/client";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import color from "@/styles/color";

const Review = () => {
  const { t } = useTranslation();
  const { isLessThanXl } = useReadScreen();

  const params = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(ContentManagementQueryKeyEnum.CUSTREVIEW, {
      Length: 2000,
      ImageType: "Thumbnail",
      Limit: 12,
    });
    return params;
  }, []);

  const { data } = useQuery<ContentManagementListResponse>({
    queryKey: [HomeQueryKeyEnum.HOME_CUSTOMER_REVIEW],
    queryFn: () => ContentManagementServices.getContentManagement(params).then((res) => res),
    enabled: !!params,
  });

  const reviewList = useMemo(() => {
    return data ? data.ContentManagement : [];
  }, [data]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: color.grayGradient100,
        position: "relative",
      }}
    >
      <Container
        maxWidth="2xl"
        sx={{
          width: "100%",
          padding: { xs: "24px", sm: "40px", xl: "40px 100px" },
          display: "flex",
          flexDirection: "column",
          gap: { xs: "16px", sm: "24px" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 600, sm: 700 },
            fontSize: { xs: "18px", sm: "24px", xl: "36px" },
            lineHeight: { xs: "27px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
          }}
        >
          {t("Home.review.title")}
        </Typography>
        <CarouselNavigateFraction
          name="home_certificate_action"
          containerSx={{
            position: "relative",
            maxWidth: { xs: "100%", xl: "100%" },
            "& .swiper-slide": {
              width: { xs: "100%", sm: "unset" },
              height: "auto",
            },
            "& .carousel_navigate_fraction_main": {
              "> .swiper-wrapper": {
                marginBottom: { xs: "12px", sm: "6px" },
              },
            },
          }}
          navigateType={isLessThanXl ? ["nvg-bullets"] : ["nvg-btn-no-fraction"]}
          isEffectCover={true}
          swiperOption={{
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto",
            spaceBetween: 42,
            loop: true,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
              scale: 0.95,
            },
          }}
          options={{
            bulletNonActiveColor: color.gray170,
            bulletsContainerSx: {
              padding: { xs: "8px 0px", sm: "24px 0px" },
              gap: { xs: "10px", sm: "20px" },
              cursor: "none",
              pointerEvents: "none",
            },
          }}
        >
          {reviewList.map((item, index) => (
            <TestimonialCard key={`testimonial-item-${index + 1}`} data={item} />
          ))}
        </CarouselNavigateFraction>
      </Container>
    </Box>
  );
};

export default Review;
