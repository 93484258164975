"use client";
/* global google */
import { Box, SxProps } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import React, { useCallback, useState } from "react";
import ButtonCustom from "../Button/ButtonCustom";
import color from "@/styles/color";
import ToyotaSureImg from "@/assets/image/toyota-logo.webp";
import { useTranslation } from "@/i18n/client";
import IconLoader from "../IconLoader";

const defaultMapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "16px",
};

const defaultMapZoom = 15;

//Map options
const defaultMapOptions: google.maps.MapOptions = {
  zoomControl: true,
  streetView: null,
  disableDefaultUI: true,
  // tilt: 0,
  // gestureHandling: "auto",
};

interface IStyle {
  sxContainer?: SxProps
  sxContainerButton?: SxProps
}

interface ISaleLocationMapProps {
  onMyLocation: (localtion?: Gelocation) => void;
  location: Gelocation
  dealerMarkers: { id: string; lat: number; lng: number }[];
  isCenter?: boolean;
  zoom: number
  sx?: IStyle
  resetSearch: () => void;
  onFilerNearMe: () => Promise<void>
}

export interface ISaleLocationMapRefProps {
  goToLocation: (pos: Gelocation) => void;
}

const SaleLocationMap = React.forwardRef<ISaleLocationMapRefProps, ISaleLocationMapProps>(
  (
    {
      onMyLocation,
      onFilerNearMe,
      dealerMarkers,
      location,
      zoom = defaultMapZoom,
      sx,
      resetSearch,
    }: ISaleLocationMapProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [centerMap, setCenterMap] = useState<Nullable<Gelocation>>(location);
    const [gMap, setGMap] = React.useState<google.maps.Map>();

    React.useImperativeHandle(ref, () => ({
      goToLocation: (pos: Gelocation) => {
        setCenterMap(pos);
        if (gMap) {
          handleAlignmentMap(gMap, pos);
        }
      },
    }));

    const handleAlignmentMap = useCallback(
      (map: google.maps.Map, pos: Gelocation) => {
        const offsetLng = 20000 / (Math.pow(2, map?.getZoom() || 1) * 256);
        map?.setCenter({ lat: pos.lat, lng: pos.lng - offsetLng });
        const offsetCenter = map?.getProjection()?.fromLatLngToPoint(pos);
        if (offsetCenter) {
          offsetCenter.x = offsetCenter.x - 20000 / (Math.pow(2, map?.getZoom() || 15) * 256);
          const newCenter = map
            ?.getProjection()
            ?.fromPointToLatLng(
              new google.maps.Point(
                offsetCenter.x - 20000 / (Math.pow(2, map?.getZoom() || 15) * 256),
                offsetCenter.y,
              ),
            );

          if (newCenter) {
            (map ?? gMap)?.setZoom(zoom);
            (map ?? gMap)?.setCenter(newCenter);
          } else {
            (map ?? gMap)?.setZoom(zoom);
            (map ?? gMap)?.setCenter(pos);
          }
        }
      },
      [gMap],
    );

    const handleNearByMeLocation = useCallback(
      (map: google.maps.Map) => {
        if (navigator?.geolocation && location && map) {
          const offsetLng = 0.00015;

          google.maps.event.addListener(map, "projection_changed", function () {
            map?.setCenter({ lat: location.lat, lng: location.lng - offsetLng });
          });

          const bounds = new window.google.maps.LatLngBounds(location);
          map.fitBounds(bounds);

          map.setZoom(zoom + 1);
          map?.setCenter({ lat: location.lat, lng: location.lng - offsetLng });
        }
      },
      [location, onMyLocation],
    );

    const onLoad = React.useCallback(
      function callback(map: google.maps.Map) {
        setGMap(map);
        handleNearByMeLocation(map);
      },
      [handleNearByMeLocation],
    );
    const onUnmount = React.useCallback(
      function callback() {
        setCenterMap(location);
      },
      [location],
    );

    return (
      <Box
        component={"div"}
        sx={{
          position: "relative",
          height: { xs: "400px", sm: "360px", xl: "615px" },
          width: "100%",
          boxShadow: "0px 4px 4px 0px #0000001A",
          borderRadius: "16px",
          ...sx?.sxContainer
        }}
      >
        {centerMap && location && (
          <GoogleMap
            mapContainerStyle={defaultMapContainerStyle}
            center={centerMap}
            zoom={zoom}
            options={defaultMapOptions}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {Object.keys(location).length > 0 && (
              <MarkerF
                position={location}
                icon={{
                  url: "/marker_location.svg",
                  scaledSize: new google.maps.Size(100, 100, "px", "px"),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(100 / 2, 100 / 2),
                }}
              />
            )}
            {dealerMarkers.length > 0 &&
              dealerMarkers.map((item, index) => (
                <MarkerF
                  key={`dealer-marker-item-${index + 1}`}
                  position={{ lat: item.lat, lng: item.lng }}
                  icon={{
                    url: ToyotaSureImg.src,
                    scaledSize: new google.maps.Size(126, 60, "px", "px"),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(126 / 2, 60 / 2),
                  }}
                />
              ))}
          </GoogleMap>
        )}
        <Box
          sx={{
            position: "absolute",
            transform: {
              xs: "translate(-50%, 0)",
              xl: "translate(-50%, 0)",
            },
            left: { xs: "50%", xl: "70%" },
            bottom: { xs: "16px", sm: "25px", xl: "30px" },
            width: { xs: "100%", sm: "unset" },
            padding: { xs: "0px 12px", sm: "unset" },
            ...sx?.sxContainerButton
          }}
        >
          <Box width={"100%"}>
            <ButtonCustom
              label={t("Map.find-dealer-nearme")}
              onClick={async () => {
                await onFilerNearMe();
                resetSearch();
              }}
              sx={{
                padding: { xs: "12px 24px", sm: "16px 24px" },
                height: { xs: "40px", xl: "48px" },
                fontSize: { xs: "14px", sm: "16px" },
                lineHeight: { xs: "23px" },
                width: { xs: "100%", sm: "fit-content" },
                ":hover": {
                  boxShadow: "unset",
                  opacity: 1,
                },
              }}
              startIcon={
                <IconLoader
                  iconName="PinIcon"
                  sx={{ fontSize: { xs: "24px", sm: "24px" }, color: color.white }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    );
  },
);

SaleLocationMap.displayName = "SaleLocationMap";

export default SaleLocationMap;
