"use client";

import { useCallback } from "react";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useTranslation } from "@/i18n/client";

import FindMyCarDrawer from "@/modules/find-my-car/FindMyCarDrawer";
import color from "@/styles/color";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import IconLoader from "@/components/IconLoader";

const FindMyCar = () => {
  const { t } = useTranslation();
  const { setShowFindMyCarDrawer } = useFindMyCarStore();

  const handleClickFindMyCar = useCallback(() => {
    setShowFindMyCarDrawer(true);
  }, [setShowFindMyCarDrawer]);

  return (
    <>
      <Stack
        sx={{
          gap: { xs: 2, sm: 3, lg: 5 },
        }}
      >
        <Typography
          sx={{
            color: color.white,
            fontWeight: 600,
            fontSize: { xs: "1rem", sm: "1.5rem", lg: "2rem" },
            lineHeight: { xs: "24px", sm: "36px", lg: "48px" },
            textAlign: "center",
            textShadow: "0px 2px 4px #1D1D1D66, 0px 4px 4px #00000040",
          }}
        >
          {t("Home.find-my-car.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: { xs: "100%", sm: "120px" },
            borderRadius: "24px",
            justifyContent: "space-between",
            // padding: "24px 40px 24px 30px",
            padding: {xs:"24px", sm: "24px 40px 24px 30px"},
            background: color.white,
            boxShadow: `0px 4px 4px 0px ${color.blackO1}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: { xs: "100%" },
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "8px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "8px", sm: "36px" },
              }}
            >
              <IconLoader
                iconName="FindMyCarIcon"
                sx={{
                  width: { xs: 44, sm: 60, lg: 80 },
                  height: "auto",
                  aspectRatio: 1,
                }}
              />
              <Typography
                fontSize={{ xs: "14px", sm: "20px", xl: "24px" }}
                fontWeight={600}
                color={color.black}
              >
                {t("Home.find-my-car.search-description")}
                
              </Typography>
            </Box>

            <ButtonBase
              onClick={handleClickFindMyCar}
              sx={{
                width: { xs: "100%", sm: "250px", md: "200px", xl: "240px" },
                height: { xs: "48px", xl: "72px" },
                borderRadius: "36px",
                padding: "24px",
                background: color.black25,
                fontSize: { xs: "16px", xl: "20px" },
                fontWeight: 600,
                color: `${color.white}!important`,
              }}
            >
              {t("Home.find-my-car.search")}
            </ButtonBase>
          </Box>
        </Box>
      </Stack>
      <FindMyCarDrawer />
    </>
  );
};

export default FindMyCar;
