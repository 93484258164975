import { useHiddenNavbarStore } from "@/contexts/useHiddenNavbar";
import { useEffect, useState } from "react";

const isBrowser = typeof window !== `undefined`;

interface IUseReadScrollPageProps {
  triggerPercentScroll: number;
  ignoreTrigger?: boolean;
}

const useReadScrollPage = ({
  triggerPercentScroll,
  ignoreTrigger = false,
}: IUseReadScrollPageProps) => {
  const scrollStore = useHiddenNavbarStore((state) => state);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    const updatePosition = () => {
      const scrollPercent =
        Math.round(
          (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) *
            100 *
            10,
        ) / 10;
      setIsShow(scrollPercent >= triggerPercentScroll);
      scrollStore.updateScrollStatus(
        scrollPercent >= triggerPercentScroll,
        scrollPercent,
        ignoreTrigger,
      );
      setScrollPosition(scrollPercent);
    };

    if (!isBrowser) return;

    window.addEventListener("scroll", updatePosition);

    return () => window.removeEventListener("scroll", updatePosition);
  }, [scrollPosition, isShow]);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  return { scrollPosition, isShow };
};

export default useReadScrollPage;
