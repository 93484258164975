import { haversineDistance } from "@/libs/helpers/calculate-distance";
import { Dealer, DealerInfo } from "@/libs/types/dealer.type";
import { MarkerLocation } from "@/libs/types/map.type";
import { create } from "zustand";

export interface DealerDistance {
  id: string;
  lat: number;
  lng: number;
  distance: number;
}

export interface IGeo {
  lat: number;
  lng: number;
}
interface IMyLocationState {
  myLocation: IGeo;
  isLoadedLocation: boolean;
  locations: DealerDistance[];
  aroundDealers: MarkerLocation[];
  setAroundDealers: (dealers: MarkerLocation[]) => void;
  isReady: () => boolean;
  initMyLocation: (cd?: () => void) => void;
  calculateDistance: (dealerInfo: Dealer[] | DealerInfo[]) => DealerDistance[];
}

const defaultLocation = {
  lat: 13.7564187,
  lng: 100.5008127,
};

const maxRetries = 1;
let retry = 0;

export const useMyLocationStore = create<IMyLocationState>((set, get) => ({
  myLocation: defaultLocation,
  isLoadedLocation: false,
  aroundDealers: [],
  locations: [],
  isReady: () => {
    return Boolean(typeof navigator?.geolocation?.getCurrentPosition === "function");
  },
  initMyLocation: (cd?: () => void) => {
    if (typeof navigator?.geolocation?.getCurrentPosition === "function") {
      navigator?.geolocation?.getCurrentPosition(
        (position: GeolocationPosition) => {
          console.log(position);
          const pos = {
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          };

          set({ myLocation: pos, isLoadedLocation: true });
          if (cd) cd();
        },
        (error) => {
          if (retry++ <= maxRetries) {
            console.log("retry: ", retry);
            set(() => ({ myLocation: defaultLocation, isLoadedLocation: false }));
            if (cd) cd();
          }
          console.log("error >>> 1", error);
        },
        {
          enableHighAccuracy: false,
          timeout: 6000,
          maximumAge: Infinity,
        },
      );
    } else {
      set(() => ({ myLocation: defaultLocation, isLoadedLocation: false }));
      if (cd) cd();
    }
  },
  setAroundDealers: (dealers: MarkerLocation[]) => {
    set({ aroundDealers: dealers });
  },
  calculateDistance: (dealerList: Dealer[] | DealerInfo[]) => {
    const { myLocation } = get();

    const dealers: DealerDistance[] = [];
    dealerList.forEach((item) => {
      const { Latitude, Longtitude } = item;
      const lat = +Latitude;
      const lng = +Longtitude;
      const distance = haversineDistance(lat, lng, myLocation.lat, myLocation.lng);

      if (!isNaN(distance)) {
        dealers.push({
          id: item.BranchId.toString(),
          lat,
          lng,
          distance,
        });
      }
    });

    const dealer = dealers.sort(
      (prevDealer, currDealer) => prevDealer.distance - currDealer.distance,
    );
    set({ locations: dealer, aroundDealers: dealer.slice(0, 11) });

    return dealer;
  },
}));
