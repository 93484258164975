"use client";
import React, { useMemo } from "react";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";

import "./CarouselNavigateFraction.scss";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import { Pagination, Navigation, Autoplay, EffectCoverflow, Grid } from "swiper/modules";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import { Theme } from "@emotion/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import color from "@/styles/color";
import { SwiperOptions } from "swiper/types";
import IconLoader from "../IconLoader";
import { _get, _range } from "@/libs/utils/helper";

interface ICarouselNavigateFractionProps {
  name: string;
  children: React.ReactNode | React.ReactNode[];
  containerSx?: SxProps<Theme>;
  slidesPerView?: number | "auto";
  navigateType: Array<
    | "nvg-btn-fraction"
    | "nvg-btn-center"
    | "nvg-bullets"
    | "nvg-bullets-dash"
    | "nvg-btn-custom"
    | "nvg-btn-no-fraction"
  >;
  swiperOption?: SwiperOptions;
  isEffectCover?: boolean;
  options?: {
    bulletNonActiveColor?: string;
    bulletsSx?: SxProps<Theme>;
    bulletsContainerSx?: SxProps<Theme>;
    prevBtnSx?: SxProps<Theme>;
    nextBtnSx?: SxProps<Theme>;
    nvgBtnContainerSx?: SxProps<Theme>;
    nvgBtnWrap?: boolean;
    nvgBtnPrevIcon?: JSX.Element;
    nvgBtnNextIcon?: JSX.Element;
  };
  getActiveSlide?: (index: number) => void;
}

export interface ICarouselNavigateFractionRefProps {
  onGoBeginSlide: () => void;
}

const CarouselNavigateFraction = React.forwardRef<
  ICarouselNavigateFractionRefProps,
  ICarouselNavigateFractionProps
>(
  (
    {
      children,
      containerSx = {},
      navigateType = [],
      swiperOption = {},
      isEffectCover = false,
      options,
      getActiveSlide,
    }: ICarouselNavigateFractionProps,
    ref,
  ) => {
    const swiperRef = React.useRef<SwiperRef>(null);
    const [snapGridCount, setSnapGridCount] = React.useState<number>(0);
    const [indexActive, setIndexActive] = React.useState<number>(0);

    const childrenLength = Array.isArray(children) ? children?.length : 0;
    const isEndSwip = _get(swiperRef, "current.swiper.isEnd", false);

    React.useImperativeHandle(ref, () => ({
      onGoBeginSlide: () => {
        swiperRef.current?.swiper.slideTo(0, 300);
      },
    }));
    React.useEffect(() => {
      const swiper = swiperRef.current?.swiper;
      setSnapGridCount(
        Math.floor(
          Number(childrenLength) /
            (typeof swiper?.params?.slidesPerView === "number"
              ? Number(swiper?.params?.slidesPerView || 1)
              : 1),
        ),
      );
    }, [childrenLength]);

    const fraction = useMemo(() => {
      const swiper = swiperRef.current?.swiper;
      if (+(swiper?.params.slidesPerGroup || 1) <= 1) {
        return `${indexActive} / ${childrenLength}`;
      }

      const total = Math.ceil(
        childrenLength /
          +(swiper?.params?.slidesPerGroup || 0) /
          (typeof swiper?.params?.grid?.rows === "number"
            ? Number(swiper?.params?.grid?.rows || 1)
            : 1),
      );
      return `${indexActive} / ${total}`;
    }, [indexActive, childrenLength]);

    return (
      <Box component={"div"} sx={{ ...containerSx }}>
        <Swiper
          watchOverflow={false}
          ref={swiperRef}
          slidesPerView={"auto"}
          spaceBetween={16}
          pagination={{
            type: "custom",
            clickable: true,
            bulletClass: "nvf_swiper-pagination-bullet",
            bulletActiveClass: "nvf_swiper-pagination-bullet-active ",
            renderCustom: (_, current) => {
              setIndexActive(current);
              if (typeof getActiveSlide === "function") {
                getActiveSlide(current);
              }
              return "";
            },
          }}
          {...swiperOption}
          modules={[
            Pagination,
            Navigation,
            Autoplay,
            ...(isEffectCover ? [EffectCoverflow] : []),
            ...(swiperOption.grid ? [Grid] : []),
          ]}
          className="carousel_navigate_fraction_main"
        >
          {Array.isArray(children) &&
            children?.map((el, index) => (
              <SwiperSlide key={`carousel-item-${index + 1}`}>{el}</SwiperSlide>
            ))}
        </Swiper>
        {navigateType.includes("nvg-btn-fraction") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "16px 0px 0px",
              gap: "24px",
            }}
          >
            <IconButton
              aria-label="slide-prev-button"
              onClick={() => {
                swiperRef.current?.swiper.slidePrev();
                // if (typeof getActiveSlide === "function") {
                //   getActiveSlide(swiperRef.current?.swiper.realIndex ?? 0);
                // }
              }}
              sx={{
                width: "40px",
                height: "40px",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                  borderColor: "unset",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  background: "transparent",
                  borderColor: "unset",
                },
              }}
            >
              {/* <ArrowBackIosNewRoundedIcon sx={{ color: color.white }} /> */}
              <IconLoader
                iconName={
                  indexActive <= 1
                    ? "BackwardFillRoundedGrayIcon"
                    : "BackwardFillRoundedRedGradientIcon"
                }
                sx={{
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                }}
              />
            </IconButton>
            <Typography
              className="panigate"
              sx={{ fontWeight: 600, color: color.gray400, fontSize: "18px", lineHeight: "25px" }}
            >
              {fraction}
            </Typography>
            <IconButton
              aria-label="slide-next-button"
              onClick={() => {
                swiperRef.current?.swiper.slideNext();
                // if (typeof getActiveSlide === "function") {
                //   getActiveSlide(swiperRef.current?.swiper.realIndex || 0);
                // }
              }}
              sx={{
                width: "40px",
                height: "40px",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                  borderColor: "unset",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  background: "transparent",
                  borderColor: "unset",
                },
              }}
            >
              {/* <ArrowForwardIosRoundedIcon sx={{ color: color.white }} /> */}
              <IconLoader
                iconName={
                  isEndSwip ? "ForwardFillRoundedGrayIcon" : "ForwardFillRoundedRedGradientIcon"
                }
                sx={{
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                }}
              />
            </IconButton>
          </Box>
        )}

        {navigateType.includes("nvg-btn-no-fraction") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "16px 0px 0px",
              gap: "24px",
              ..._get(options, "nvgBtnContainerSx", {}),
            }}
          >
            <IconButton
              aria-label="slide-prev-button"
              onClick={() => {
                swiperRef.current?.swiper.slidePrev();
                if (typeof getActiveSlide === "function") {
                  const index =
                    typeof swiperRef.current?.swiper?.realIndex === "number"
                      ? swiperRef.current?.swiper?.realIndex === 0
                        ? Number(childrenLength) - 1
                        : swiperRef.current?.swiper?.realIndex - 1
                      : 0;
                  getActiveSlide(index);
                }
              }}
              sx={{
                width: "40px",
                height: "40px",
                background:
                  swiperRef.current?.swiper?.params?.loop !== true && indexActive <= 1
                    ? color.gray180
                    : color.redGradient,
                "&:hover": {
                  backgroundColor:
                    swiperRef.current?.swiper?.params?.loop !== true && indexActive <= 1
                      ? color.gray180
                      : color.redGradient,
                  borderColor: "unset",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor:
                    swiperRef.current?.swiper?.params?.loop !== true && indexActive <= 1
                      ? color.gray180
                      : color.redGradient,
                  borderColor: "unset",
                },
              }}
            >
              <ArrowBackIosNewRoundedIcon sx={{ color: color.white }} />
            </IconButton>
            <IconButton
              aria-label="slide-next-button"
              onClick={() => {
                swiperRef.current?.swiper.slideNext();
                if (typeof getActiveSlide === "function") {
                  getActiveSlide(swiperRef.current?.swiper?.realIndex || 0);
                }
              }}
              sx={{
                width: "40px",
                height: "40px",
                background:
                  swiperRef.current?.swiper.params.loop !== true &&
                  indexActive >=
                    Number(childrenLength) -
                      (swiperRef.current?.swiper?.params?.slidesPerView !== "auto"
                        ? Number(swiperRef.current?.swiper?.params?.slidesPerView || 1) - 1
                        : 0)
                    ? color.gray180
                    : color.redGradient,
                "&:hover": {
                  backgroundColor:
                    swiperRef.current?.swiper.params.loop !== true &&
                    indexActive >=
                      Number(childrenLength) -
                        (swiperRef.current?.swiper?.params?.slidesPerView !== "auto"
                          ? Number(swiperRef.current?.swiper.params?.slidesPerView || 1) - 1
                          : 0)
                      ? color.gray180
                      : color.redGradient,
                  borderColor: "unset",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor:
                    swiperRef.current?.swiper?.params?.loop !== true &&
                    indexActive >=
                      Number(childrenLength) -
                        (swiperRef.current?.swiper?.params?.slidesPerView !== "auto"
                          ? Number(swiperRef.current?.swiper?.params?.slidesPerView || 1) - 1
                          : 0)
                      ? color.gray180
                      : color.redGradient,
                  borderColor: "unset",
                },
              }}
            >
              <ArrowForwardIosRoundedIcon sx={{ color: color.white }} />
            </IconButton>
          </Box>
        )}
        {/* <div style={{ color: "red" }}>{swiperRef.current?.swiper.snapGrid.length}</div> */}
        {navigateType.includes("nvg-bullets") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "24px 0px",
              gap: "10px",
              flexWrap: { xs: "wrap" },
              ..._get(options, "bulletsContainerSx", {}),
            }}
          >
            {_range(snapGridCount).map((index) => (
              <Box
                key={`dot-item-${index + 1}`}
                component={"div"}
                sx={{
                  width: { xs: "12px", sm: "15px" },
                  height: { xs: "12px", sm: "15px" },
                  borderRadius: "100%",
                  cursor: "pointer",
                  background:
                    indexActive === index + 1
                      ? color.redGradient
                      : _get(options, "bulletNonActiveColor", color.white),
                  ..._get(options, "bulletsSx", {}),
                }}
                onClick={() => {
                  swiperRef.current?.swiper?.slideTo(index, 300, true);
                }}
              ></Box>
            ))}
          </Box>
        )}

        {navigateType.includes("nvg-bullets-dash") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "24px 0px",
              gap: "10px",
              ..._get(options, "bulletsContainerSx", {}),
            }}
          >
            {_range(snapGridCount).map((index) => (
              <Box
                key={`dot-item-${index + 1}`}
                component={"div"}
                sx={{
                  width: indexActive === index + 1 ? "80px" : "12px",

                  height: { xs: "12px", sm: "12px" },
                  borderRadius: indexActive === index + 1 ? "10px" : "100%",
                  cursor: "pointer",
                  transition: "width 0.5s",
                  background:
                    indexActive === index + 1
                      ? color.redGradient
                      : _get(options, "bulletNonActiveColor", color.white),
                  ..._get(options, "bulletsSx", {}),
                }}
                onClick={() => swiperRef.current?.swiper.slideTo(index, 300, true)}
              ></Box>
            ))}
          </Box>
        )}

        {navigateType.includes("nvg-btn-center") &&
          (!swiperRef.current?.swiper?.isBeginning || !swiperRef.current?.swiper?.isEnd) && (
            <>
              <IconButton
                aria-label="slide-prev-button"
                onClick={() => swiperRef.current?.swiper.slidePrev()}
                sx={{
                  position: "absolute",
                  top: "20%",
                  left: { xs: "-20px", xl: "-25px" },
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                  zIndex: 99,
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                    borderColor: "unset",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    background: "transparent",
                    borderColor: "unset",
                  },
                  display:
                    swiperRef.current?.swiper?.isBeginning && _get(options, "nvgBtnWrap", false)
                      ? "none"
                      : "flex",
                  ..._get(options, "prevBtnSx", {}),
                }}
              >
                <IconLoader
                  iconName={
                    indexActive <= 1
                      ? "BackwardFillRoundedGrayIcon"
                      : "BackwardFillRoundedRedGradientIcon"
                  }
                  sx={{
                    width: { xs: "40px", xl: "50px" },
                    height: { xs: "40px", xl: "50px" },
                  }}
                />
              </IconButton>
              <IconButton
                aria-label="slide-next-button"
                onClick={() => swiperRef.current?.swiper.slideNext()}
                sx={{
                  position: "absolute",
                  top: "20%",
                  right: { xs: "-20px", xl: "-25px" },
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                  zIndex: 99,
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                    borderColor: "unset",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    background: "transparent",
                    borderColor: "unset",
                  },
                  display: isEndSwip && _get(options, "nvgBtnWrap", false) ? "none" : "flex",
                  ..._get(options, "nextBtnSx", {}),
                }}
              >
                <IconLoader
                  iconName={
                    isEndSwip ? "ForwardFillRoundedGrayIcon" : "ForwardFillRoundedRedGradientIcon"
                  }
                  sx={{
                    width: { xs: "40px", xl: "50px" },
                    height: { xs: "40px", xl: "50px" },
                  }}
                />
              </IconButton>
            </>
          )}

        {navigateType.includes("nvg-btn-custom") &&
          (!swiperRef.current?.swiper?.isBeginning || !swiperRef.current?.swiper?.isEnd) && (
            <>
              <IconButton
                aria-label="slide-prev-button"
                onClick={() => {
                  if (typeof getActiveSlide === "function") {
                    const index =
                      typeof swiperRef.current?.swiper.realIndex === "number"
                        ? swiperRef.current?.swiper.realIndex === 0
                          ? Number(childrenLength) - 1
                          : swiperRef.current?.swiper.realIndex - 1
                        : 0;

                    getActiveSlide(index);
                  }
                  swiperRef.current?.swiper.slidePrev();
                }}
                sx={{
                  position: "absolute",
                  top: "20%",
                  left: { xs: "-20px", xl: "-25px" },
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                  zIndex: 99,
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                    borderColor: "unset",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    background: "transparent",
                    borderColor: "unset",
                  },
                  ..._get(options, "prevBtnSx", {}),
                }}
              >
                {options?.nvgBtnPrevIcon ? (
                  options.nvgBtnPrevIcon
                ) : (
                  <IconLoader
                    iconName="BackwardFillRoundedRedGradientIcon"
                    sx={{
                      width: { xs: "40px", xl: "50px" },
                      height: { xs: "40px", xl: "50px" },
                    }}
                  />
                )}
              </IconButton>
              <IconButton
                aria-label="slide-next-button"
                onClick={() => {
                  swiperRef.current?.swiper.slideNext();
                  if (typeof getActiveSlide === "function") {
                    getActiveSlide(swiperRef.current?.swiper.realIndex || 0);
                  }
                }}
                sx={{
                  position: "absolute",
                  top: "20%",
                  right: { xs: "-20px", xl: "-25px" },
                  width: { xs: "40px", xl: "50px" },
                  height: { xs: "40px", xl: "50px" },
                  zIndex: 99,
                  background: "transparent",
                  "&:hover": {
                    background: "transparent",
                    borderColor: "unset",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    background: "transparent",
                    borderColor: "unset",
                  },

                  ..._get(options, "nextBtnSx", {}),
                }}
              >
                {options?.nvgBtnNextIcon ? (
                  options.nvgBtnNextIcon
                ) : (
                  <IconLoader
                    iconName="ForwardFillRoundedRedGradientIcon"
                    sx={{
                      width: { xs: "40px", xl: "50px" },
                      height: { xs: "40px", xl: "50px" },
                    }}
                  />
                )}
              </IconButton>
            </>
          )}
      </Box>
    );
  },
);

CarouselNavigateFraction.displayName = "CarouselNavigateFraction";

export default CarouselNavigateFraction;
