"use client";

import color from "@/styles/color";
import { SxProps } from "@mui/material";
import React from "react";
import { parseAsInteger, useQueryState } from "nuqs";

export type TArticleBtnGroup = "all" | "know_tip" | "news";
export type TFaqBtnGroup = "all" | string;
export const ArticleBtnActive: SxProps = {
  height: { xs: "35px", xl: "56px" },
  width: { xs: "fit-content", sm: "fit-content" },
  padding: { xs: "10px", sm: "24px", xl: "24px 48px" },
  fontSize: { xs: "12px", sm: "16px", xl: "20px" },
  lineHeight: { xs: "23px", sm: "22px", xl: "24px" },
  background: color.redGradient,
  color: color.white100,
  ":hover": {
    background: color.redGradient,
    color: color.white100,
    boxShadow: "unset",
    opacity: 0.7,
  },
};

export const ArticleBtnNonActive: SxProps = {
  fontWeight: 400,
  height: { xs: "35px", xl: "56px" },
  width: { xs: "fit-content", sm: "fit-content" },
  padding: { xs: "10px", sm: "24px", xl: "24px 48px" },
  fontSize: { xs: "12px", sm: "16px", xl: "20px" },
  lineHeight: { xs: "23px", sm: "22px", xl: "24px" },
  background: color.gray120,
  color: color.gray400,
  ":hover": {
    background: color.gray120,
    color: color.gray400,
    boxShadow: "unset",
    opacity: 0.7,
  },
};

function useHome() {
  const [articleButtonGroup, setArticleBtnGroup] = React.useState<TArticleBtnGroup>("all");
  const [faqBtnGroup, setFaqBtnGroup] = React.useState<TFaqBtnGroup>("all");
  const [dealerId] = useQueryState("dealerid", parseAsInteger);
  return {
    articleButtonGroup,
    faqBtnGroup,
    dealerId,

    setArticleBtnGroup,
    setFaqBtnGroup,
  };
}

export default useHome;
