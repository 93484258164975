"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Box, Container, IconButton, Typography } from "@mui/material";

import ButtonCustom from "@/components/Button/ButtonCustom";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import ArticleHomeCard from "@/components/Card/ArticleHomeCard";
import CarouselNavigateFraction, {
  ICarouselNavigateFractionRefProps,
} from "@/components/CarouselCommon/CarouselNavigateFraction";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import TextSearchInput from "@/components/Input/TextSearchInput/TextSearchInput";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import color from "@/styles/color";
import { useTranslation } from "@/i18n/client";
import { useRouter } from "next/navigation";
import { ArticleBtnActive, ArticleBtnNonActive } from "@/hooks/useHome.hook";
import IconLoader from "@/components/IconLoader";
import SkeletonArticle from "@/components/Skeleton/SkeletonArticle";
import ArticleNotFound from "../Articles/components/ArticleNotFound";

const Article = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [_, setTypeArticle] = useState<string>("all-page");
  const { isLessThanSm, isLessThanMd } = useReadScreen();
  const [articleButtonGroup, setArticleBtnGroup] = useState<ContentManagementQueryKeyEnum>(
    ContentManagementQueryKeyEnum.ALLARTICLES
  );

  const [textToSearchNotFound, setTextToSearchNotFound] = useState<string>();
  const [textToSearch, setTextSearch] = useState("");
  const handleKeyEnter = (event: any) => {
    if (event.key === "Enter") {
      setTextToSearchNotFound(textToSearch);
    }
  };

  const [enabledQuery, setEnabledQuery] = useState<boolean>(false);
  const carouselNvgArticleMapRef = React.useRef<ICarouselNavigateFractionRefProps>(null);

  const articleParams = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(articleButtonGroup, {
      Length: 2000,
      ImageType: isLessThanMd ? "Thumbnail Mobile" : "Thumbnail",
      Limit: isLessThanMd ? 10 : 12,
      SearchText: textToSearchNotFound,
      SortBy: articleButtonGroup == ContentManagementQueryKeyEnum.KNOWTIP ? "UpdateDate" : "CreateDate",
      SortDirection: "DESC",
    });
    return params;
  }, [articleButtonGroup, textToSearchNotFound]);

  const {
    data: articleList,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [HomeQueryKeyEnum.HOME_ARTICLE, articleButtonGroup, textToSearchNotFound],
    queryFn: () => ContentManagementServices.getContentManagement(articleParams),
    placeholderData: keepPreviousData,
  });

  const onChangeActiveButton = useCallback(
    (filter: { key: ContentManagementQueryKeyEnum }) => {
      setArticleBtnGroup(filter.key);
      setEnabledQuery(true);
      carouselNvgArticleMapRef.current?.onGoBeginSlide();
    },
    [setArticleBtnGroup, setEnabledQuery],
  );

  useEffect(() => {
    if (enabledQuery) {
      refetch();
    }
  }, [enabledQuery, refetch]);

  useEffect(() => {
    if (articleButtonGroup == ContentManagementQueryKeyEnum.KNOWTIP) {
      setTypeArticle("knowtip-page");
    }
    if (articleButtonGroup == ContentManagementQueryKeyEnum.CUSTNEWS) {
      setTypeArticle("news-page");
    }
  }, [articleButtonGroup]);

  const buttonFilter = useMemo(() => {
    return [
      {
        key: ContentManagementQueryKeyEnum.ALLARTICLES,
      },
      {
        key: ContentManagementQueryKeyEnum.KNOWTIP,
      },
      {
        key: ContentManagementQueryKeyEnum.CUSTNEWS,
      },
    ];
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: color.white,
        position: "relative",
      }}
    >
      <Container
        maxWidth="2xl"
        sx={{
          width: "100%",
          padding: { xs: "24px", sm: "40px", xl: "40px 100px" },
          display: "flex",
          flexDirection: "column",
          gap: { xs: "0px", sm: "24px" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 600, sm: 700 },
            fontSize: { xs: "18px", sm: "24px", xl: "36px" },
            lineHeight: { xs: "27px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
            paddingTop: { xs: "16px", sm: "unset" },
          }}
        >
          {t(`Home.article.title`)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            paddingTop: { xs: "16px", sm: "unset" },
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            width: "100%",
            rowGap: "16px",
          }}
        >
          <Box
            id="btn-filter-article-group"
            sx={{ display: "flex", gap: { xs: "16px", sm: "8px", md: "16px" } }}
            justifyContent={{
              xs: "unset",
              sm: "center",
              md: "unset",
            }}
          >
            {buttonFilter.map((filter) => {
              const isActive = articleButtonGroup === filter.key;
              return (
                <ButtonCustom
                  key={`button-filter-article-${filter.key}`}
                  label={t(`Home.article.${filter.key}`)}
                  onClick={() => {
                    onChangeActiveButton(filter);
                  }}
                  sx={{
                    height: { xs: "40px", xl: "56px" },
                    width: { xs: "100%", sm: "fit-content" },
                    padding: { xs: "8px", sm: "24px", xl: "24px 48px" },
                    fontSize: { xs: "12px", sm: "16px", xl: "20px" },
                    lineHeight: { xs: "23px", sm: "22px", xl: "24px" },
                    ...(isActive ? ArticleBtnActive : ArticleBtnNonActive),
                  }}
                />
              );
            })}
          </Box>
          <Box
            id="article-filter-search-group"
            sx={{
              display: "flex",
              gap: { xs: "16px", sm: "8px", md: "16px" },
            }}
          >
            <TextSearchInput
              placeholder={t(`Home.article.placeholder`)}
              value={textToSearch}
              iconSx={{
                fontSize: "26px",
              }}
              InputProps={{
                sx: {
                  borderRadius: "60px !important",
                  fontWeight: 400,
                  fontSize: { xs: "18px", sm: "16px", xl: "18px" },
                  lineHeight: { xs: "22px", sm: "22px" },
                  color: color.black,
                  background: color.gray120,
                  height: { xs: "40px", xl: "56px" },
                  width: { xs: "100%", md: "282px", xl: "480px" },
                  padding: "16px",
                },
              }}
              sx={{
                "& input::placeholder": {
                  opacity: 1,
                  color: color.grey,
                  fontWeight: 400,
                  fontSize: { xs: "18px", sm: "16px" },
                  lineHeight: { xs: "22px", sm: "22px" },
                },
              }}
              onChange={(event) => {
                setTextSearch(event.target.value);
              }}
              onKeyDown={handleKeyEnter}
            />
            <IconButton
              sx={{
                background: color.redGradient,
                width: { xs: "40px", xl: "56px" },
                height: { xs: "40px", xl: "56px" },
                justifyContent: "center",
              }}
              onClick={() => {
                if (textToSearch == "") {
                  setTextToSearchNotFound(undefined);
                } else {
                  setTextToSearchNotFound(textToSearch);
                }
              }}
            >
              <IconLoader iconName="SearchBoldIcon" sx={{ fontSize: { xs:"20px" ,lg: "28px"}, color: color.white }} />
            </IconButton>
            <IconButton
              sx={{
                background: color.redGradient,
                width: { xs: "40px", xl: "56px" },
                height: { xs: "40px", xl: "56px" },
                justifyContent: "center",
              }}
              onClick={() => {
                  setTextSearch("");
                  setTextToSearchNotFound(undefined);
              }}
            >
              <IconLoader iconName="BinIcon" sx={{ fontSize: { xs:"20px" ,lg: "25px"}, color: color.white }} />
            </IconButton>
          </Box>
        </Box>
        <DividerCustom
          sx={{
            width: "100%",
            display: { xs: "none", xl: "flex" },
            "&.MuiDivider-root": {
              margin: "0px",
            },
            borderColor: color.gray170,
          }}
        />
        {articleList?.TotalCount ? (
          <Box sx={{ width: "100%", paddingTop: { xs: "16px", sm: "unset" } }}>
            {isFetching ? (
              <SkeletonArticle itemCount={3} />
            ) : (
              <CarouselNavigateFraction
                ref={carouselNvgArticleMapRef}
                name="home_article_action"
                containerSx={{
                  position: "relative",
                  maxWidth: { xs: "100%", xl: "100%" },
                  height: "auto",
                  "& .swiper-slide": {
                    width: { xs: "unset", md: "unset", xl: "100%" },
                  },
                  "& .carousel_navigate_fraction_main": {
                    "> .swiper-wrapper": {
                      marginBottom: { xs: "12px", sm: "unset" },
                    },
                  },
                }}
                navigateType={isLessThanSm ? ["nvg-bullets"] : ["nvg-btn-center"]}
                swiperOption={{
                  slidesPerView: 3,
                  spaceBetween: 20,
                  breakpoints: {
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 16,
                      slidesPerGroup: 1,
                    },
                    600: {
                      slidesPerView: 2,
                      spaceBetween: 16,
                      slidesPerGroup: 2,
                    },
                    834: {
                      slidesPerView: 2,
                      spaceBetween: 16,
                      slidesPerGroup: 2,
                    },
                    1194: {
                      slidesPerView: 3,
                      spaceBetween: 16,
                      slidesPerGroup: articleList?.TotalCount > 2 ? 3 : articleList?.TotalCount,
                    },
                  },
                }}
                options={{
                  bulletNonActiveColor: color.gray170,
                }}
              >
                {articleList?.ContentManagement && articleList.ContentManagement.length > 0
                  ? articleList.ContentManagement.map((item, index) => (
                      <ArticleHomeCard key={`article-item-${index + 1}`} data={item} />
                    ))
                  : []}
              </CarouselNavigateFraction>
            )}
          </Box>
        ) : (
          <ArticleNotFound textToSearchNotFound={textToSearchNotFound} />
        )}
        <>
          {articleButtonGroup !== ContentManagementQueryKeyEnum.ALLARTICLES && (
            <Box
              sx={{
                paddingTop: { xs: "0px", sm: "24px" },
              }}
            >
              <ButtonOutlinedCustom
                label="ดูทั้งหมด"
                sx={{
                  alignItems: "center",
                  "& .MuiButton-endIcon>*:nth-of-type(1)": {
                    fontSize: "24px",
                  },
                }}
                onClick={() =>
                  articleButtonGroup == ContentManagementQueryKeyEnum.KNOWTIP
                    ? router.push("/home/knowledge")
                    : router.push("home/news")
                }
                endIcon={
                  <IconLoader
                    iconName="ForwardFillRoundedRedGradientIcon"
                    sx={{ fontSize: "32px" }}
                  />
                }
              />
            </Box>
          )}
        </>
      </Container>
    </Box>
  );
};

export default Article;
