import color from "@/styles/color";
import { Box, Typography } from "@mui/material";
import React from "react";
import ImageCustom from "../Image/ImageCustom";
import dayjs from "dayjs";
import htmlToText from "@/libs/common/html-to-text";
import { ContentManagement } from "@/libs/types/content-management.type";

interface ITestimonialCardProps {
  data: ContentManagement;
}

function TestimonialCard({ data }: ITestimonialCardProps) {
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "543px", lg: "627px", xl: "704px" },
        bgcolor: color.white,
        borderRadius: "12px",
        height: { xs: "180px", sm: "260px", lg: "300px", xl: "338px" },
        boxShadow: "0px 4px 4px 0px #0000001A",
        display: "flex",
        gap: { xs: "16px", md: "20px", xl: "24px" },
      }}
    >
      <Box
        sx={{
          height: { xs: "180px", sm: "260px", lg: "300px", xl: "338px" },
          width: {
            xs: "152px",
            sm: "220px",
            md: "253px",
            xl: "285px",
          },
        }}
      >
        {data?.ImageFileURL && (
          <ImageCustom
            imageSrc={data.ImageFileURL}
            imageAlt="Testimonial Image"
            sx={{
              img: {
                objectFit: "cover",
                borderRadius: "12px 0 0 12px",
                minWidth: {
                  xs: "152px !important",
                  sm: "220px !important",
                  md: "253px !important",
                  xl: "285px !important",
                },
                width: {
                  xs: "152px !important",
                  sm: "220px !important",
                  md: "253px !important",
                  xl: "285px !important",
                },
                height: {
                  xs: "100% !important",
                },
              },
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: {
            xs: "12px 16px 12px 0px",
            sm: "16px 16px 16px 0px",
            md: "20px 20px 20px 0px",
            xl: "30px 24px 30px 0px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: { xs: "8px", xl: "12px" },
            lineHeight: { xs: "12px", xl: "18px" },
            color: color.gray400,
          }}
        >
          {data.CustomerName || ""}
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: "12px", sm: "16px", xl: "24px" },
            lineHeight: { xs: "18px", sm: "24px", xl: "36px" },
            color: color.gray400,
          }}
        >
          {data.Description ? htmlToText.htmlToText(data.Description, { wordwrap: 80 }) : ""}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "8px", xl: "12px" },
              lineHeight: { xs: "12px", xl: "18px" },
              color: color.gray400,
            }}
          >
            {data.DealerDisplayName || ""}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "8px", xl: "12px" },
              lineHeight: { xs: "12px", xl: "18px" },
              color: color.gray400,
            }}
          >
            {data.CreatedOn ? dayjs(new Date(data.CreatedOn)).format("DD/MM/YYYY") : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TestimonialCard;
