import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import color from "@/styles/color";
import { IToyotaCertificateItem } from "@/libs/types/toyota-certificate.type";
import ImageCustom from "../Image/ImageCustom";

interface IToyotaCertificateHomeCardProps {
  data: IToyotaCertificateItem;
}

function ToyotaCertificateHomeCard({ data }: IToyotaCertificateHomeCardProps) {
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "16px",
        width: "100%",
        height: "auto",
        alignSelf: "stretch",
        padding: { xs: "24px", sm: "24px 30px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: color.white,
      }}
    >
      <Stack sx={{ width: "100%", gap: 2 }}>
        <ImageCustom
          imageSrc={data?.image || ""}
          imageAlt="Cert Thumbnail"
          sx={{
            minWidth: "100%",
            img: {
              objectFit: "cover",
              borderRadius: "16px",
              width: {
                xs: "30px !important",
                sm: "40px !important",
              },
              height: {
                xs: "30px !important",
                sm: "40px !important",
              },
            },
          }}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
            lineHeight: { xs: "18px", sm: "24px", lg: "30px" },
            color: color.gray400,
            whiteSpace: "pre-wrap",
          }}
        >
          {data.title}
        </Typography>
        {data.description && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "0.75rem", sm: "1rem" },
              lineHeight: { xs: "18px", sm: "27px" },
              color: color.gray190,
              paddingTop: { xs: "8px", sm: "8px" },
            }}
          >
            {data.description}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default ToyotaCertificateHomeCard;
