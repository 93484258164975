"use client";

import color from "@/styles/color";
import { Box, Fade, Typography } from "@mui/material";
import React from "react";
import { DividerCustom } from "../Divider/DividerCustom";
import ButtonCustom from "../Button/ButtonCustom";
import { GreyBorderBtn } from "@/styles/customStyle";
import { ArticleBtnActive, ArticleBtnNonActive } from "@/hooks/useHome.hook";
import { ContentManagement } from "@/libs/types/content-management.type";
import { useTranslation } from "@/i18n/client";
import { useRouter } from "next/navigation";
import IconLoader from "../IconLoader";
import "../../styles/show-image-video-best-solution.css";
import { _get, _unique } from "@/libs/utils/helper";

interface IAnswerFaqComponentProps {
  answer: string;
  isShow: boolean;
  id: string;
}

interface IFAQHomeProps {
  items: ContentManagement[];
}

function FAQHome({ items }: Readonly<IFAQHomeProps>) {
  const { t } = useTranslation();
  const router = useRouter();
  const [faqBtnGroup, setFaqBtnGroup] = React.useState<string>("all");

  const keyBtnGroup = React.useMemo(() => {
    return _unique(
      items.flatMap((value) => {
        return Array.isArray(value.Tags) && value.Tags.length > 0
          ? value.Tags.map((item) => item.Tag)
          : [];
      }),
    );
  }, [items]);

  const faqItems = React.useMemo(() => {
    return faqBtnGroup === "all"
      ? items
      : items.filter(
          (item) => Array.isArray(item.Tags) && item.Tags.map((el) => el.Tag).includes(faqBtnGroup),
        );
  }, [faqBtnGroup, items]);

  const [faqSelected, setFaqSelected] = React.useState<ContentManagement | null>(
    _get(faqItems, "0"),
  );

  const AnswerFaqComponent = ({ answer, isShow, id }: IAnswerFaqComponentProps) => {
    return (
      <Fade in={isShow}>
        <Box
          sx={{
            borderRadius: "24px 0px 24px 24px",
            bgcolor: color.pink200,
            padding: { xs: "20px", xl: "32px 32px" },
            display: "flex",
            flexDirection: "column",
            gap: { xs: "16px", xl: "40px" },
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontSize: { xs: "14px", xl: "20px" },
              lineHeight: { xs: "21px", xl: "30px" },
              color: color.black300,
            }}
            className="best-solution"
            dangerouslySetInnerHTML={{ __html: answer }}
          ></Typography>
          <GreyBorderBtn
            sx={{
              fontSize: { xs: "16px", xl: "16px" },
              padding: "0px",
              height: { xs: "fit-content" },
              width: { xs: "fit-content" },
              border: { xs: "unset" },
              background: "transparent !important",
              color: `${color.red} !important`,
              textDecorationLine: { xs: "underline" },
              transition: "unset",
              ":active": {
                background: "transparent !important",
                boxShadow: "unset",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={handleReadMore(id)}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: 400,
                fontSize: { xs: "14px", xl: "20px" },
                lineHeight: { xs: "21px", xl: "30px" },
                background: color.redGradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {t("Home.faq.read-more")}
            </Typography>
          </GreyBorderBtn>
        </Box>
      </Fade>
    );
  };

  const handleRouterFaqPage = React.useCallback(() => {
    router.push("/home/faq");
  }, [router]);

  const handleReadMore = React.useCallback(
    (id: string) => () => {
      router.push(`/home/faq/${id}`);
    },
    [router],
  );

  React.useEffect(() => {
    setFaqSelected(_get(faqItems, "0"));
  }, [faqBtnGroup, faqItems]);

  const handleChangeTab = React.useCallback(
    (value: string) => () => {
      setFaqBtnGroup(value);
    },
    [],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "24px 16px", sm: "24px", xl: "40px" },
        bgcolor: color.white,
        borderRadius: "24px",
        width: "100%",
        height: "auto",
        gap: { xs: "16px", xl: "32px" },
      }}
    >
      <Box
        sx={{ order: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: { xs: "16px", sm: "24px", xl: "32px" },
            lineHeight: { xs: "24px", sm: "36px", xl: "48px" },
            color: color.gray400,
          }}
        >
          {t("Home.faq.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: "8px",
          }}
          onClick={handleRouterFaqPage}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "16px", sm: "24px" },
              lineHeight: { xs: "36px", sm: "36px" },
              color: color.gray400,
            }}
          >
            {t("Home.faq.more")}
          </Typography>
          <IconLoader
            iconName="DirectLinkGradientIcon"
            sx={{ fontSize: { xs: "28px", xl: "48px" } }}
          />
        </Box>
      </Box>
      <DividerCustom
        sx={{
          order: { xs: 2, xl: 1 },
          borderColor: color.gray170,
        }}
      />
      <Box
        sx={{
          order: { xs: 1, xl: 2 },
          display: "flex",
          gap: { xs: "8px", xl: "16px" },
          paddingTop: { xs: "0px", xl: "8px" },
        }}
      >
        <ButtonCustom
          label="ทั้งหมด"
          onClick={handleChangeTab("all")}
          sx={{
            ...(faqBtnGroup === "all" ? ArticleBtnActive : ArticleBtnNonActive),
          }}
        />
        {keyBtnGroup.map((item, index) => (
          <ButtonCustom
            key={`key-faq-btn-item-${index + 1}`}
            label={item}
            onClick={handleChangeTab(item)}
            sx={{
              ...(faqBtnGroup === item ? ArticleBtnActive : ArticleBtnNonActive),
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          order: 3,
          display: "flex",
          paddingTop: "8px",
          width: "100%",
          gap: { xs: "unset", xl: "80px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", xl: "32px" },
            width: "100%",
          }}
        >
          {faqItems.map((item, index) => (
            <Box
              key={`faq-item-${index + 1}`}
              sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <Box
                component={"div"}
                onClick={() => {
                  setFaqSelected(item);
                }}
                sx={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {faqSelected?.ContentId && faqSelected.ContentId === item?.ContentId && (
                  <IconLoader
                    iconName="FAQQuestionIcon"
                    sx={{ fontSize: { xs: "37px", xl: "54px" } }}
                  />
                )}
                <Box
                  sx={{
                    borderRadius: {
                      xs: "0px 16px 16px 16px",
                      xl: faqSelected?.ContentId === item.ContentId ? "0px 16px 16px 16px" : "16px",
                    },
                    padding: { xs: "8px 20px", xl: "16px 40px" },
                    display: "inline-flex",
                    background:
                      faqSelected?.ContentId === item.ContentId
                        ? "linear-gradient(white, white) padding-box, linear-gradient(90deg, #F10503 0%, #CF0301 100%) border-box"
                        : "linear-gradient(white, white) padding-box, linear-gradient(90deg, #E8E8E8 0%, #E8E8E8 100%) border-box",
                    border: `1px solid transparent`,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: "14px", xl: "24px" },
                      lineHeight: { xs: "21px", xl: "36px" },
                      color: color.black200,
                    }}
                  >
                    {item.Title}
                  </Typography>
                </Box>
              </Box>
              {faqSelected?.ContentId === item?.ContentId && (
                <Box sx={{ width: "100%", display: { xs: "unset", xl: "none" } }}>
                  <AnswerFaqComponent
                    id={faqSelected?.ContentId}
                    isShow={faqSelected?.ContentId === item?.ContentId}
                    answer={faqSelected?.Description || ""}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <Box sx={{ width: "100%", display: { xs: "none", xl: "unset" } }}>
          {faqSelected && (
            <AnswerFaqComponent
              isShow={Boolean(faqSelected)}
              answer={faqSelected?.Description || ""}
              id={faqSelected?.ContentId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default FAQHome;
