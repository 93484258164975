"use client";

import { useMemo } from "react";
import Image from "next/image";
import { Box, Stack } from "@mui/material";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import Link from "next/link";
import { PagePath } from "@/libs/constant/path";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { useQuery } from "@tanstack/react-query";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { useReadScreen } from "@/hooks/useReadScreen.hook";

const DealPromotion = () => {
  const { isLessThanSm } = useReadScreen();
  const params = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(ContentManagementQueryKeyEnum.DEAL, {
      Length: 1,
      ImageType: isLessThanSm ? "Thumbnail Mobile" : "Thumbnail",
      Limit: 6,
    });
    return params;
  }, []);

  const { data } = useQuery<ContentManagementListResponse>({
    queryKey: [HomeQueryKeyEnum.HOME_HERO_DEALER_BANNER],
    queryFn: () => ContentManagementServices.getContentManagement(params).then((res) => res),
    enabled: !!params,
  });

  const promotionList = useMemo(() => {
    return data?.ContentManagement?.filter((item) => item?.ImageFileURL) ?? [];
  }, [data]);

  return (
    <Stack
      flexDirection="row"
      gap={2}
      sx={{
        width: "100%",
        flexWrap: "wrap",
        "> *": {
          flex: { xs: "1 calc(50% - 16px)", sm: "1 calc(100% / 3 - 16px)" },
        },
      }}
    >
      {promotionList?.map((promotion) => {
        return (
          <Link
            key={`promotion-banner-${promotion.ContentId}`}
            href={`${PagePath.wantToBuy}?content=${promotion.ContentId}`}
            rel="noopener noreferrer"
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "auto",
                maxWidth: "400px",
                aspectRatio: { xs: 155 / 93, sm: 2 / 1 },
                img: {
                  aspectRatio: { xs: 155 / 93, sm: 2 / 1 },
                },
              }}
            >
              <Image
                alt={promotion.Title || "Promotion image"}
                loading="lazy"
                priority={false}
                fill
                src={promotion.ImageFileURL ?? ""}
                sizes="50vw"
              />
            </Box>
          </Link>
        );
      })}
    </Stack>
  );
};

export default DealPromotion;
