import color from "@/styles/color";
import { Skeleton, Stack } from "@mui/material";

const SkeletonSuggestVehicle = ({ itemCount }: { itemCount: number }) => (
  <Stack
    data-testid="skeleton-vehicle-stack"
    flexDirection="row"
    gap={2}
    sx={{
      width: "100%",
      height: { lg: "695px" },
      flexWrap: "wrap",
      "> *": {
        flex: { xs: `1 calc((100% / ${itemCount}) - 16px)` },
      },
    }}
  >
    {Array.from({ length: itemCount }).map((_, index) => (
      <Skeleton
        data-testid="skeleton-vehicle-item"
        key={`skeleton-${index}`}
        variant="rectangular"
        animation="wave"
        width="100%"
        height="100%"
        sx={{
          borderRadius: "23px",
          boxShadow: `0px 4px 4px 0px ${color.blackO1}`,
        }}
      />
    ))}
  </Stack>
);

export default SkeletonSuggestVehicle;
