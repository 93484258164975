export enum HomeQueryKeyEnum {
  HOME_HERO_BANNER = "home-hero-banner",
  HOME_HERO_BANNER_MOBILE = "home-hero-banner-mobile",
  HOME_HERO_BANNER_VIDEO = "home-hero-banner-video",
  HOME_HERO_BANNER_VIDEO_MOBILE = "home-hero-banner-video-mobile",
  HOME_HERO_DEALER_BANNER = "home-dealer-hero-banner",
  HOME_DEALER = "home-dealer",
  HOME_PROMOTION = "home-promotion",
  HOME_FAQ = "home-faq",
  HOME_CUSTOMER_REVIEW = "home-customer-review",
  HOME_ARTICLE = "home-article",
  HOME_SUGGEST_VEHICLE = "home-suggest-vehicle",
  HOME_PRD_CONTENT = "home-prd-content",
  HOME_FAQ_CONTENT = "home-faq-content",
  DEALER_LOCALTION_LIST = "home-dealer-localtion"
}

export enum SuggestVehicleSortEnum {
  New = "new",
  Popular = "popular",
  Suggestion = "suggestion",
}
