"use client";

import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import { Box, Container, FormControl, IconButton, Typography } from "@mui/material";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import NextDynamic from "next/dynamic";

import { useMyLocationStore } from "@/contexts/useMyLocation";
import TextSearchInput from "@/components/Input/TextSearchInput/TextSearchInput";
import ButtonCustom from "@/components/Button/ButtonCustom";
import { MapProvider } from "@/components/providers/MapProvider";
import SaleLocationMap, { ISaleLocationMapRefProps } from "@/components/SaleLocationMap";
import CarouselNavigateFraction, {
  ICarouselNavigateFractionRefProps,
} from "@/components/CarouselCommon/CarouselNavigateFraction";
import DealerLocationDetailCard from "@/components/Card/DealerLocationDetailCard";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { MarkerLocation } from "@/libs/types/map.type";
import { DealerServices } from "@/libs/services/dealer";
import { MasterDataSearchKeyWordEnum } from "@/libs/enum/master-search-key.enum";
import { MasterServices } from "@/libs/services/master/master.service";
import color from "@/styles/color";
import { Controller, useForm } from "react-hook-form";
import { Dealer, SearchDealer } from "@/libs/types/dealer.type";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { useTranslation } from "@/i18n/client";
import IconLoader from "@/components/IconLoader";
import { MasterData, MasterDataParams, MasterDataResponse } from "@/libs/types/master-data.type";
import SelectCustom from "@/components/SearchCars/SearchCarsSelectCustom";
import { DealerKeys, DealerQueryKeyEnum } from "@/libs/enum/dealer-query.enum";

const NotFoundDealer = NextDynamic(() => import("../dealer/components/NotFoundDealer"), {
  ssr: false,
});

const DealerMap = () => {
  const offsetLng = 0.01;
  const { t } = useTranslation("", {
    keyPrefix: "Home.dealer",
  });

  const { myLocation, isLoadedLocation, aroundDealers, calculateDistance } = useMyLocationStore();
  const { isLessThanXl, isLessThanSm, isBetweenXsAndSm, isBetweenLgAndxl, isBetweenSmAndLg } =
    useReadScreen();
  const carouselNvgDealerMapRef = useRef<ICarouselNavigateFractionRefProps>(null);
  const mapNvgDealerMapRef = useRef<ISaleLocationMapRefProps>(null);
  const [dealerNearMe, setDealerNearMe] = useState<MarkerLocation>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [querySearchDealer, setQuerySearchDealer] = useState<SearchDealer>({
    SearchText: "DealerMap",
  });
  const dealerSearchForm = useForm<SearchDealer>({
    defaultValues: {},
  });

  const { watch } = dealerSearchForm;
  const [regionId, provinceId, SearchText] = watch([
    "RegionId",
    "ProvinceId",
    "SearchText",
    "BranchId",
  ]);
  const disabledSearch = Boolean(
    (regionId && !provinceId) ||
      (!SearchText && ((regionId && !provinceId) || (!regionId && !provinceId))),
  );

  const fetchMasterData = async (params: MasterDataParams) => {
    const response = await MasterServices.getMasterData(params);
    return response;
  };

  const {
    data: responseDealerLocation,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: [DealerKeys.DEALER_LOCALTION_LIST, { ...querySearchDealer }],
    queryFn: () =>
      DealerServices.getDealerList({ ...querySearchDealer }).then((res) => {
        return res.DealerInfo;
      }),
    placeholderData: keepPreviousData,
  });

  const { data: resMasterRegion } = useQuery<MasterData[]>({
    queryKey: [MasterDataSearchKeyWordEnum.REGION],
    queryFn: () =>
      fetchMasterData({ SearchKeyword: MasterDataSearchKeyWordEnum.REGION }).then(
        (res) => res.SearchFilter,
      ),
  });

  const paramsProvince = {
    ...(regionId && {
      Region: regionId,
    }),
  };

  const paramsDealer = {
    ...(provinceId && {
      ProvinceId: provinceId,
    }),
  };

  const { data: responseProvince, refetch: refetchProvince } = useQuery<MasterDataResponse>({
    enabled: false,
    queryKey: [MasterDataSearchKeyWordEnum.PROVINCE, paramsProvince],
    queryFn: () =>
      MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.DEALERPROVINCE,
        Region: paramsProvince.Region,
      }),
  });

  const { data: dealersList, refetch: refetchDealers } = useQuery<Dealer[]>({
    enabled: false,
    queryKey: [DealerQueryKeyEnum.DEALER_LIST, paramsDealer],
    queryFn: () => DealerServices.getDealerList(paramsDealer).then((res) => res.DealerInfo || []),
  });

  const regionList = useMemo(() => {
    return (resMasterRegion || [])
      .filter((item) => item.Id !== 0)
      .map(({ Id, Name }) => ({
        value: Id.toString(),
        label: Name,
      }));
  }, [resMasterRegion]);

  const provinceList = useMemo(() => {
    return (responseProvince?.SearchFilter || [])
      .filter((item) => item.Id !== 0 && regionId)
      .map(({ Id, Name }) => ({
        value: Id.toString(),
        label: Name,
      }));
  }, [responseProvince, regionId]);

  const dealerList = useMemo(() => {
    return (dealersList || [])
      .filter((item) => item.DealerId !== 0 && provinceId)
      .map((item) => ({
        label: item.DealerDisplayName.concat(" ", item.BranchDisplayName),
        value: item.BranchId,
      }));
  }, [dealersList, provinceId]);

  const dealerInfoMarkers = useMemo(() => {
    return (responseDealerLocation || [])
      ?.filter((item) => item.DealerId !== 0)
      .map((dealer) => ({
        id: dealer.BranchId?.toString(),
        lat: Number(dealer.Latitude),
        lng: Number(dealer.Longtitude),
      }));
  }, [responseDealerLocation]);

  const onSubmitSearch = dealerSearchForm.handleSubmit(
    async ({ DealerId, SearchText, ...rest }) => {
      const values = Object.entries(rest).reduce((prev, [key, value]) => {
        if (!value) return prev;

        return {
          ...prev,
          [key]: +value,
        };
      }, {});
      setQuerySearchDealer({ SearchText, ...values });
    },
  );

  const calculateDealerDistance = useCallback(
    (_dealers: Dealer[]) => {
      const dealers = calculateDistance(_dealers).slice(0, 11);
      setDealerNearMe(dealers?.[0]);
    },
    [responseDealerLocation, dealerNearMe, isLessThanXl],
  );

  const onFilterNearMe = useCallback(async () => {
    setQuerySearchDealer({ SearchText: "DealerMap" });
    setCurrentIndex(0);

    const dealer = calculateDistance(responseDealerLocation || []);
    const _dealer = dealer?.[0] || undefined;
    if (_dealer) {
      const lng = isLessThanXl ? _dealer?.lng : _dealer?.lng - offsetLng;
      mapNvgDealerMapRef.current?.goToLocation({ ..._dealer, lng });
    }

    carouselNvgDealerMapRef.current?.onGoBeginSlide();
  }, [
    dealerNearMe,
    isLessThanXl,
    currentIndex,
    querySearchDealer,
    responseDealerLocation,
    mapNvgDealerMapRef.current,
    carouselNvgDealerMapRef.current,
  ]);

  const delay = async (sec: number) => {
    return new Promise((resolve) => setTimeout(resolve, sec));
  };
  const resetFilters = useCallback(() => {
    dealerSearchForm.reset();
    setQuerySearchDealer({ SearchText: "DealerMap" });
  }, [dealerSearchForm, querySearchDealer]);

  const dealerLocationDataMemo = useMemo(() => {
    return aroundDealers;
  }, [aroundDealers]);

  const onFocusDealer = useCallback(async () => {
    const dealer = dealerLocationDataMemo?.[currentIndex];
    await delay(400).then(() => {
      const lng = isLessThanXl ? dealer.lng : dealer.lng - offsetLng;
      if (mapNvgDealerMapRef.current) {
        mapNvgDealerMapRef.current?.goToLocation({ lat: dealer.lat, lng });
      }
    });
  }, [isLessThanXl, dealerLocationDataMemo, currentIndex]);

  useEffect(() => {
    if (regionId) {
      refetchProvince().finally(() => {
        dealerSearchForm.setValue("ProvinceId", "");
        dealerSearchForm.setValue("DealerId", "");
      });
    }
  }, [regionId]);

  useEffect(() => {
    if (provinceId) {
      refetchDealers().finally(() => dealerSearchForm.setValue("DealerId", ""));
    }
  }, [provinceId]);

  useEffect(() => {
    if (responseDealerLocation?.length || isLoadedLocation)
      calculateDealerDistance(responseDealerLocation || []);
  }, [responseDealerLocation, isLoadedLocation]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: color.grayGradient,
        position: "relative",
      }}
    >
      <Container
        maxWidth="2xl"
        sx={{
          width: "100%",
          padding: { xs: "24px 24px 0px 24px", sm: "40px", xl: "40px 100px" },
          display: "flex",
          flexDirection: "column",
          gap: { xs: "16px", sm: "24px" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 600, sm: 700 },
            fontSize: { xs: "18px", sm: "24px", xl: "36px" },
            lineHeight: { xs: "27px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
          }}
        >
          {t("title")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            backgroundColor: { xs: "transparent", sm: color.white },
            padding: { xs: "0px", sm: "24px" },
            borderRadius: { xs: "16px" },
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px" },
          }}
        >
          <RHFFormProvider methods={dealerSearchForm} onSubmit={onSubmitSearch}>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "8px", sm: "16px" },
                width: "100%",
                padding: { xs: "16px", sm: "0px" },
                backgroundColor: { xs: color.white, sm: "unset" },
                borderRadius: { xs: "16px", sm: "unset" },
                flexDirection: { xs: "column", xl: "row" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", xl: "417px" },
                  minWidth: { xs: "100%", xl: "auto" },
                }}
              >
                <Controller
                  name={"SearchText"}
                  control={dealerSearchForm.control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextSearchInput
                        placeholder={t("placeholder.searchText")}
                        showIcon={false}
                        InputProps={{
                          sx: {
                            borderRadius: "16px !important",
                            fontWeight: 600,
                            fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                            lineHeight: { xs: "20px", sm: "22px" },
                            color: color.black,
                            background: color.gray120,
                            height: { xs: "40px", xl: "60px" },
                            width: "100%",
                            padding: "16px 24px",
                          },
                        }}
                        sx={{
                          "& input::placeholder": {
                            opacity: 1,
                            color: color.grey,
                            fontWeight: 500,
                            fontSize: { xs: "12px", sm: "16px", xl: "18px" },
                            lineHeight: { xs: "20px", sm: "22px", xl: "22px" },
                          },
                        }}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: { xs: "8px", xl: "16px" },
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    flex: { xs: "1 calc(50% - 4px)", sm: "unset" },
                    flexGrow: { xs: 1, sm: "unset" },
                  }}
                >
                  <SelectCustom
                    label={""}
                    options={regionList}
                    dataKey={"RegionId"}
                    registerForm={dealerSearchForm.register("RegionId")}
                    formControlSx={{
                      flex: { xs: "1 calc(50% - 4px)", sm: "unset" },
                      flexGrow: { xs: 1, sm: "unset" },
                      width: { xs: "100%", md: "100%", xl: "217px" },
                    }}
                    placeholder={t("placeholder.region")}
                    sx={{
                      "& input::placeholder": {
                        opacity: 1,
                        color: color.grey,
                        fontWeight: 500,
                        fontSize: { xs: "12px", sm: "16px", xl: "18px" },
                        lineHeight: { xs: "20px", sm: "22px", xl: "22px" },
                      },
                      borderRadius: "16px !important",
                      fontWeight: 500,
                      fontSize: { xs: "12px", sm: "16px", xl: "18px !important" },
                      lineHeight: { xs: "19px", sm: "19px", xl: "19px" },
                      color: color.black,
                      background: color.gray120,
                      height: { xs: "40px", xl: "60px" },
                      width: "100% !important",
                      paddingX: "24px !important",
                      textAlign: "center",
                      alignContent: "center",
                      "& .MuiSvgIcon-fontSizeMedium": {
                        fontSize: "11px !important",
                      },
                    }}
                    inputSx={{
                      "& .MuiAutocomplete-input": {
                        height: "auto",
                        padding: { xs: "auto", xl: "16.5px 14px !important" },
                      },
                      fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                    }}
                    popupIcon={
                      <IconLoader
                        iconName="ArrowDownAccordionIcon"
                        sx={{ fontSize: "11px !important", color: color.gray400 }}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    flex: { xs: "1 calc(50% - 4px)", sm: "unset" },
                    flexGrow: { xs: 1, sm: "unset" },
                  }}
                >
                  <SelectCustom
                    label={""}
                    disabled={!Boolean(dealerSearchForm.getValues("RegionId"))}
                    options={provinceList}
                    dataKey={"ProvinceId"}
                    id="search-map-province"
                    registerForm={dealerSearchForm.register("ProvinceId")}
                    formControlSx={{
                      flex: { xs: "1 calc(50% - 4px)", sm: "unset" },
                      flexGrow: { xs: 1, sm: "unset" },
                      width: { xs: "100%", md: "100%", xl: "217px" },
                    }}
                    placeholder={t("placeholder.province")}
                    sx={{
                      "& input::placeholder": {
                        opacity: 1,
                        color: color.grey,
                        fontWeight: 500,
                        fontSize: { xs: "12px", sm: "16px", xl: "18px" },
                        lineHeight: { xs: "20px", sm: "20px", xl: "20px" },
                      },
                      borderRadius: "16px !important",
                      fontWeight: 500,
                      fontSize: { xs: "12px", sm: "16px", xl: "18px !important" },
                      lineHeight: { xs: "20px", sm: "22px", xl: "22px" },
                      color: color.black,
                      background: color.gray120,
                      height: { xs: "40px", xl: "60px" },
                      width: "100% !important",
                      paddingX: "24px !important",
                      textAlign: "center",
                      alignContent: "center",
                      "& .MuiSvgIcon-fontSizeMedium": {
                        fontSize: "11px !important",
                      },
                    }}
                    inputSx={{
                      "& .MuiAutocomplete-input": {
                        height: "auto",
                        padding: { xs: "auto", xl: "16.5px 14px !important" },
                      },
                    }}
                    popupIcon={
                      <IconLoader
                        iconName="ArrowDownAccordionIcon"
                        sx={{ fontSize: "11px !important", color: color.gray400 }}
                      />
                    }
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <SelectCustom
                    label={""}
                    options={dealerList}
                    disabled={!Boolean(dealerSearchForm.getValues("ProvinceId"))}
                    dataKey={"BranchId"}
                    id="search-map-dealer"
                    registerForm={dealerSearchForm.register("DealerId")}
                    formControlSx={{
                      flex: { xs: "1 calc(50% - 4px)", sm: "unset" },
                      flexGrow: { xs: 1, sm: "unset" },
                      width: { xs: "100%", md: "100%", xl: "217px" },
                    }}
                    placeholder={t("placeholder.dealer")}
                    sx={{
                      "& input::placeholder": {
                        opacity: 1,
                        color: color.grey,
                        fontWeight: 500,
                        fontSize: { xs: "12px", sm: "16px", xl: "18px" },
                        lineHeight: { xs: "20px", sm: "20px", xl: "20px" },
                      },
                      borderRadius: "16px !important",
                      fontWeight: 500,
                      fontSize: { xs: "12px", sm: "16px", xl: "18px !important" },
                      lineHeight: { xs: "20px", sm: "22px", xl: "22px" },
                      color: color.black,
                      background: color.gray120,
                      height: { xs: "40px", xl: "60px" },
                      width: "100% !important",
                      paddingX: "24px !important",
                      textAlign: "center",
                      alignContent: "center",
                      "& .MuiSvgIcon-fontSizeMedium": {
                        fontSize: "11px !important",
                      },
                    }}
                    inputSx={{
                      "& .MuiAutocomplete-input": {
                        height: "auto",
                        padding: { xs: "auto", xl: "16.5px 14px !important" },
                      },
                    }}
                    popupIcon={
                      <IconLoader
                        iconName="ArrowDownAccordionIcon"
                        sx={{ fontSize: "11px !important", color: color.gray400 }}
                      />
                    }
                  />
                </Box>
                <IconButton
                  type="submit"
                  disabled={disabledSearch}
                  sx={{
                    background: color.redGradient,
                    opacity: disabledSearch ? "0.4" : "unset",
                    width: { xs: "40px", xl: "60px" },
                    height: { xs: "40px", xl: "60px" },
                    justifyContent: "center",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <IconLoader iconName="SearchIcon" sx={{ fontSize: "28px", color: color.white }} />
                </IconButton>
                <IconButton
                  sx={{
                    background: color.redGradient,
                    width: { xs: "40px", xl: "60px" },
                    height: { xs: "40px", xl: "60px" },
                    justifyContent: "center",
                    display: { xs: "none", sm: "flex" },
                  }}
                  onClick={() => {
                    resetFilters();
                    onFilterNearMe();
                  }}
                >
                  <IconLoader iconName="BinIcon" sx={{ fontSize: "22px", color: color.white }} />
                </IconButton>
                <ButtonCustom
                  label={t("button-text")}
                  type="submit"
                  disabled={disabledSearch}
                  sx={{
                    background: color.redGradient100,
                    opacity: disabledSearch ? "0.4" : "unset",
                    fontSize: "16px",
                    lineHeight: "23px",
                    display: { xs: "flex", sm: "none" },
                  }}
                />
                <ButtonCustom
                  label={t("button-reset-text")}
                  type="button"
                  onClick={() => {
                    resetFilters();
                    onFilterNearMe();
                  }}
                  sx={{
                    background: color.redGradient100,
                    fontSize: "16px",
                    lineHeight: "23px",
                    display: { xs: "flex", sm: "none" },
                  }}
                />
              </Box>
            </Box>
          </RHFFormProvider>
          <Box
            sx={{
              position: "relative",
              display: { xs: "flex" },
              flexDirection: { xs: "column", xl: "unset" },
              gap: { xs: "24px", xl: "unset" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: { xs: "16px", sm: "0px" },
                backgroundColor: { xs: color.white, md: "unset" },
                borderRadius: { xs: "16px", md: "unset" },
              }}
            >
              <MapProvider fallback={<Box>Loading</Box>}>
                <SaleLocationMap
                  zoom={14}
                  location={myLocation}
                  ref={mapNvgDealerMapRef}
                  onMyLocation={() => {}}
                  onFilerNearMe={async () => {
                    resetFilters();
                    onFilterNearMe();
                  }}
                  isCenter={isLessThanXl}
                  dealerMarkers={dealerInfoMarkers}
                  resetSearch={() => {
                    dealerSearchForm.reset();
                  }}
                />
              </MapProvider>
            </Box>
            {Boolean(dealerLocationDataMemo?.length > 0) && (
              <Box
                sx={{
                  position: { xs: "unset", xl: "absolute" },
                  backgroundColor: { xs: "transparent", md: color.white },
                  padding: { xs: "0px", xl: "16px" },
                  left: { xs: "unset", xl: "16px" },
                  bottom: { xs: "unset", xl: "16px" },
                  borderRadius: { xs: "unset", xl: "16px" },
                  zIndex: 5,
                }}
              >
                <CarouselNavigateFraction
                  ref={carouselNvgDealerMapRef}
                  name="home_dealer_action"
                  getActiveSlide={(index) => {
                    const activeSlideIndex = index - 1;
                    setCurrentIndex(activeSlideIndex);
                  }}
                  containerSx={{
                    maxWidth: { xs: "100%", xl: "555px" },
                    position: "relative",
                    "& .swiper-slide": {
                      width: { xs: "unset", md: "unset", xl: "100%" },
                    },
                    "& .carousel_navigate_fraction_main": {
                      "> .swiper-wrapper": {
                        marginBottom: { xs: "12px", md: "unset" },
                      },
                    },
                  }}
                  navigateType={isLessThanSm ? ["nvg-bullets"] : ["nvg-btn-fraction"]}
                  slidesPerView={isBetweenXsAndSm || isLessThanXl ? "auto" : 1}
                  swiperOption={{
                    slidesPerView: "auto",
                    breakpoints: {
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                      },
                      600: {
                        slidesPerView: "auto",
                        spaceBetween: 16,
                      },
                    },
                  }}
                >
                  {dealerLocationDataMemo.map((item, index) => {
                    const isFocus = +item.id === +dealerLocationDataMemo?.[currentIndex]?.id;
                    const isPreFocus =
                      (isBetweenLgAndxl || isBetweenSmAndLg) &&
                      dealerLocationDataMemo?.[currentIndex + 1] &&
                      +item.id === +dealerLocationDataMemo?.[currentIndex + 1]?.id;

                    return (
                      <DealerLocationDetailCard
                        key={`dealer-item-${index + 1}`}
                        distance={+item.distance.toFixed(1)}
                        branchId={isFocus || isPreFocus ? Number(item.id) : NaN}
                        callback={onFocusDealer}
                      />
                    );
                  })}
                </CarouselNavigateFraction>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      <NotFoundDealer
        onRetry={() => {
          resetFilters();
          onFilterNearMe();
        }}
        isError={Boolean(!responseDealerLocation?.length && (!isLoading || isFetched))}
      />
    </Box>
  );
};

export default DealerMap;
