"use client";

import React, { useMemo } from "react";
import { useRouter } from "next/navigation";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "@/i18n/client";
import dayjs from "dayjs";

import ButtonOutlinedRedGradientCustom from "../Button/ButtonOutlinedRedGradientCustom";
import ImageCustom from "../Image/ImageCustom";
import { removeHTMLTag, thousandComma } from "@/libs/common/string";
import IconLoader from "../IconLoader";
import { ContentManagement } from "@/libs/types/content-management.type";
import color from "@/styles/color";

interface IArticleHomeCardProps {
  data: ContentManagement;
}

function ArticleHomeCard({ data }: IArticleHomeCardProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const description = useMemo(() => {
    const description = removeHTMLTag(data?.Description || "");
    return description;
  }, [data]);

  const articleLabel = useMemo(() => {
    return t(`Home.article.${data.ReferenceCode}`);
  }, [t, data]);

  return (
    <Box component={"a"} href={`/contentdetail/contentdetailsui/${data.ContentId.toLowerCase()}`}>
      <Box
        sx={{
          width: "100%",
          // width: { xs: "100%", lg: "360px", xl: "400px" },
          // minWidth: { xs: "100%", lg: "360px", xl: "400px" },
          borderRadius: "16px",
          display: "flex",
          flexDirection: { xs: "column" },
          gap: { xs: "16px" },
          height: "auto",
          minHeight: { xs: "unset", sm: "490px", lg: "502px", xl: "527px" },
          maxHeight: { xs: "435px", sm: "490px", lg: "502px", xl: "527px" },
          background: "transparent",
          cursor: "pointer",
          justifyContent: "space-between",
        }}
        onClick={() =>
          router.push(`/contentdetail/contentdetailsui/${data.ContentId.toLowerCase()}`)
        }
      >
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "16px" },
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: { xs: "226px", sm: "235px", xl: "260px" },
                width: { xs: "100%", xl: "100%" },
              }}
            >
              {data?.ImageFileURL && (
                <ImageCustom
                  imageSrc={data.ImageFileURL}
                  imageAlt="Dealer Thumbnail"
                  sx={{
                    minWidth: "100%",
                    img: {
                      objectFit: "cover",
                      borderRadius: "16px",
                      width: {
                        xs: "100% !important",
                        sm: "100% !important",
                        xl: "100% !important",
                      },
                      height: {
                        xs: "226px !important",
                        sm: "235px !important",
                        xl: "260px !important",
                      },
                    },
                  }}
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  left: "10px",
                  bottom: { xs: "10px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    width: "fit-content",
                    padding: "0px 8px",
                    background: color.blackOp60,
                    borderRadius: "16px",
                  }}
                >
                  <IconLoader
                    iconName="EyeViewOutlineIcon"
                    sx={{ fontSize: "24px", color: color.white }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: "14px" },
                      lineHeight: { xs: "24px" },
                      color: color.white,
                    }}
                  >
                    {thousandComma(data?.ViewCount || 0)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "16px", sm: "20px", xl: "24px" },
              lineHeight: { xs: "24px", sm: "36px" },
              color: color.gray400,
              paddingTop: { xs: "8px" },
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              maxHeight: "80px",
              marginBottom: "10px",
            }}
          >
            {data?.Title || ""}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: { xs: "14px", sm: "16px", xl: "18px" },
              lineHeight: { xs: "21px", sm: "27px" },
              color: color.gray190,
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              marginBottom: "10px",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            <IconLoader
              iconName="CalendarOutlineIcon"
              sx={{ fontSize: { xs: "18px", sm: "24px" }, color: color.gray190 }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: { xs: "12px", sm: "16px" },
                lineHeight: { xs: "24px" },
                color: color.gray190,
              }}
            >
              {t("Vehicle.article.publish_date")}{" "}
              {dayjs(new Date(data.CreatedOn)).format("DD/MM/YYYY")}
            </Typography>
          </Box>
          <ButtonOutlinedRedGradientCustom
            label={articleLabel}
            sx={{ width: "fit-content", height: { xs: "32px", sm: "45px" } }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ArticleHomeCard;
