import { create } from "zustand";

interface IMyLocationState {
  isShow: boolean;
  percentScroll: number;
  ignoreState: boolean;
  updateScrollStatus: (isShow: boolean, percent: number, ignoreState: boolean) => void;
  reset: () => void;
}

export const useHiddenNavbarStore = create<IMyLocationState>((set) => ({
  isShow: false,
  percentScroll: 0,
  ignoreState: false,
  updateScrollStatus: (isShow, percentScroll, ignoreState) => {
    set({ isShow, percentScroll, ignoreState });
  },
  reset: () => {
    set({ isShow: false, percentScroll: 0, ignoreState: false });
  },
}));
