"use client";
import color from "@/styles/color";
import { Button, ButtonProps, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

interface IButtonOutlinedCustomProps extends ButtonProps {
  label: string;
  labelSx?: SxProps<Theme>;
}

function ButtonOutlinedRedGradientCustom({
  label,
  labelSx = {},
  ...rest
}: IButtonOutlinedCustomProps) {
  return (
    <Button
      variant="outlined"
      {...rest}
      sx={{
        width: "100%",
        height: "45px",
        borderRadius: "100px",
        padding: "12px 16px",
        // fontWeight: 600,
        // fontSize: { xs: "10px", sm: "11px", md: "12px", lg: "12px", xl: "14px" },
        // lineHeight: { xs: "21px", sm: "21px", md: "21px", lg: "21px", xl: "21px" },
        border: `1px solid ${color.red}`,
        background: color.white,
        // border: "none",
        // outline: "none",
        // position: "relative",
        // zIndex: 1,
        // boxShadow: "unset",
        ":hover": {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: color.white,
          border: `1px solid ${color.red}`,
        },
        // ":before": {
        //   content: '""',
        //   position: "absolute",
        //   left: "1px",
        //   right: "1px",
        //   top: "1px",
        //   marginTop: "1px",
        //   bottom: "1px",
        //   borderRadius: "100px",
        //   backgroundColor: color.white,
        //   zIndex: -1,
        //   transition: "200ms",
        // },
        // ":after": {
        //   content: "attr(data)",
        //   background: color.redGradient,
        //   WebkitBackgroundClip: "text",
        //   color: "transparent",
        //   transition: "200ms",
        // },
        ...rest?.sx,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "12px", xl: "14px" },
          lineHeight: { xs: "21px", sm: "21px", md: "21px", lg: "21px", xl: "21px" },
          background: color.redGradient,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          ...labelSx,
        }}
      >
        {label}
      </Typography>
    </Button>
  );
}

export default ButtonOutlinedRedGradientCustom;
