import { FC, Fragment, useMemo } from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
  AccordionProps,
  AccordionSummaryProps,
  Chip,
  Typography,
  Stack,
  Grid,
  GridTypeMap,
  SxProps,
} from "@mui/material";
import dayjs from "dayjs";

import color from "@/styles/color";
import { Dealer } from "@/libs/types/dealer.type";
import utc from "dayjs/plugin/utc";
import IconLoader from "../IconLoader";
import { useTranslation } from "@/i18n/client";

dayjs.extend(utc);

type AccordionDealerOpenDayProps = {
  showIcon?: boolean;
  showScroll?: boolean;
  data: Dealer;
  sx: {
    gridLeft: GridTypeMap;
    gridRight: GridTypeMap;
  };
  accordionContainerSx?: SxProps;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  padding: "0px",
  border: "0px",
  color: color.white,
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => {
  return (
    <MuiAccordionSummary
      expandIcon={<IconLoader iconName="ArrowDownGradientIcon" sx={{ fontSize: "14px" }} />}
      {...props}
    />
  );
})(({ theme }) => ({
  minHeight: 0,
  padding: 0,
  [theme.breakpoints.up("xs")]: {
    gap: "8px",
    justifyContent: "flex-start",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(0deg) !important",
    background: "transparent",
    borderRadius: "100%",
    "--color-stop-1": color.red,
    "--color-stop-2": color.red110,
    svg: {
      transform: "rotate(0deg)",
    },
    "&.Mui-expanded": {
      background: "transparent",
      "--color-stop-1": color.red,
      "--color-stop-2": color.red110,
      svg: {
        transform: "rotate(180deg)",
      },
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      flexGrow: 0,
    },
  },
}));

const openTimeMap: Record<"open" | "close", { color: string; bgcolor: string }> = {
  close: {
    color: color.red,
    bgcolor: color.pink100,
  },
  open: {
    color: color.green200,
    bgcolor: color.green100,
  },
};

const AccordionDealerOpenDay: FC<AccordionDealerOpenDayProps> = ({
  data,
  sx,
  showIcon = false,
  showScroll = false,
  accordionContainerSx,
}) => {
  const { t } = useTranslation()
  const currentOpenHours = useMemo(() => {
    const currentDay = dayjs().format("dddd");
    return data?.OpenHours?.find((date) => date.Day.toLowerCase() === currentDay.toLowerCase());
  }, []);

  const isOpenTime = useMemo(() => {
    if (!currentOpenHours) {
      return false;
    }
    const { OpenTime, CloseTime } = currentOpenHours;
    const currentTime = dayjs();
    const openTime = dayjs(`${currentTime.format("YYYY-MM-DD")} ${OpenTime}`, "YYYY-MM-DD HH:mm");
    const closeTime = dayjs(`${currentTime.format("YYYY-MM-DD")} ${CloseTime}`, "YYYY-MM-DD HH:mm");

    return currentTime.isAfter(openTime) && currentTime.isBefore(closeTime);
  }, [currentOpenHours, data]);

  const openCloseTime = useMemo(() => {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          ...(showScroll ? { maxHeight: "153px", overflowY: "auto", ...accordionContainerSx } : {}),
        }}
      >
        {data?.OpenHours?.map((day, index) => (
          <Fragment key={`open-days-${index}`}>
            <Grid item {...sx.gridLeft.props}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: "14px" },
                  lineHeight: { xs: "20px" },
                  color: color.gray400,
                }}
              >
                {day.Day}
              </Typography>
            </Grid>
            <Grid item {...sx.gridRight.props}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: { xs: "20px" },
                  color: color.gray400,
                }}
              >
                {`${day.OpenTime} AM - ${day.CloseTime} PM`}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    );
  }, [data, sx, showScroll]);

  return (
    <Stack
      flexDirection="row"
      sx={{
        alignItems: "center",
        gap: "8px",
        justifyItems: "start",
      }}
    >
      {showIcon && (
        <IconLoader
          iconName="ClockGradientIcon"
          sx={{
            fontSize: "24px",
            marginTop: "1px",
            placeSelf: "flex-start",
          }}
        />
      )}

      <Accordion
        sx={{
          xs: "max-content",
        }}
      >
        <AccordionSummary>
          <Stack
            flexDirection="row"
            sx={{
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Chip
              label={t(isOpenTime ? "Dealer.card.open-time" : "Dealer.card.close-time")}
              sx={{
                alignSelf: "flex-start",
                width: "fit-content",
                height: { xs: "24px" },
                borderRadius: { xs: "30px" },
                fontWeight: 600,
                fontSize: { xs: "0.8rem", sm: "0.8rem", xl: "0.9rem" },
                lineHeight: { xs: "1rem", sm: "1rem", xl: "1.18rem" },
                color: openTimeMap[isOpenTime ? "open" : "close"].color,
                backgroundColor: openTimeMap[isOpenTime ? "open" : "close"].bgcolor,
              }}
            />
            {isOpenTime && (
              <Typography
                sx={{
                  color: color.gray400,
                  fontSize: { xs: "0.875rem", xl: "1rem" },
                  fontWeight: 300,
                }}
              >
                {`${currentOpenHours?.OpenTime} AM - ${currentOpenHours?.CloseTime} PM`}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginTop: 1,
            padding: "0px",
            backgroundColor: "transparent",
          }}
        >
          {openCloseTime}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default AccordionDealerOpenDay;
