"use client";

import React, { useEffect, useMemo } from "react";
import Image from "next/image";
import { Box } from "@mui/material";

import CarouselNavigateFraction from "@/components/CarouselCommon/CarouselNavigateFraction";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import color from "@/styles/color";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import useReadScrollPage from "@/hooks/useReadScrollPage.hook";
import IconLoader from "@/components/IconLoader";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { useQuery } from "@tanstack/react-query";
import { useMyLocationStore } from "@/contexts/useMyLocation";
import Link from "next/link";
import { PagePath } from "@/libs/constant/path";

const BannerHome = () => {
  const { isLessThanSm } = useReadScreen();
  const { initMyLocation } = useMyLocationStore();
  useReadScrollPage({ triggerPercentScroll: 0.2, ignoreTrigger: true });
  const [isPrevBtn, setIsPrevBtn] = React.useState<boolean>(false);

  const params = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(ContentManagementQueryKeyEnum.TMTBAN, {
      Length: 1,
      BannerUploadType: "Image",
      ImageType: isLessThanSm ? "Mobile" : "PC",
      Limit: 6,
    });
    return params;
  }, [isLessThanSm]);

  const { data: resBanners } = useQuery<ContentManagementListResponse>({
    queryKey: [
      isLessThanSm ? HomeQueryKeyEnum.HOME_HERO_BANNER_MOBILE : HomeQueryKeyEnum.HOME_HERO_BANNER,
    ],
    queryFn: () => ContentManagementServices.getContentManagement(params).then((res) => res),
    enabled: !!params,
  });
  const paramsVideo = useMemo(() => {
    const params = new ContentManagementQueryMap();
    params.set(ContentManagementQueryKeyEnum.TMTBAN, {
      BannerUploadType: "Video",
      Limit: 6,
    });
    return params;
  }, []);

  const { data: resBannersVideo } = useQuery<ContentManagementListResponse>({
    queryKey: [HomeQueryKeyEnum.HOME_HERO_BANNER_VIDEO],
    queryFn: () => ContentManagementServices.getContentManagement(paramsVideo).then((res) => res),
    enabled: !!paramsVideo,
  });

  const bannerList = React.useMemo(() => {
    return resBanners?.ContentManagement
      ? resBanners?.ContentManagement.filter(
          (item) => item?.ImageFileURL !== null && item?.ImageFileURL !== "",
        )
      : [];
  }, [resBanners]);

  const bannerVideoList = React.useMemo(() => {
    return resBannersVideo?.ContentManagement
      ? resBannersVideo?.ContentManagement.filter(
          (item) => item?.ImageFileURL !== null && item?.ImageFileURL !== "",
        )
      : [];
  }, [resBannersVideo]);

  useEffect(() => {
    initMyLocation();
  }, []);
  return (
    <CarouselNavigateFraction
      name="home_banner_action"
      containerSx={{
        position: "relative",
        maxWidth: { xs: "100%", xl: "100%" },
        height: "100%",
        background: color.redDarkGradient,
        minHeight: {
          xs: "844px",
          sm: "512px",
          lg: "746px",
          xl: "900px",
        },
        "& .swiper-slide": {
          width: "100vw !important",
        },
        "& .carousel_navigate_fraction_main": {
          "> .swiper-wrapper": {},
        },
      }}
      navigateType={isLessThanSm ? ["nvg-bullets-dash"] : ["nvg-bullets-dash", "nvg-btn-custom"]}
      swiperOption={{
        slidesPerView: 1,
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      }}
      getActiveSlide={(value) => {
        setIsPrevBtn(value > 0);
      }}
      options={{
        bulletNonActiveColor: color.gray170,
        prevBtnSx: {
          top: "50%",
          left: { xs: "40px", xl: "44px" },
          maxWidth: { xs: 20, sm: 40, xl: 80 },
          maxHeight: { xs: 20, sm: 40, xl: 80 },
          display: isPrevBtn ? "inline-flex" : "none",
        },
        nextBtnSx: {
          top: "50%",
          right: { xs: "40px", xl: "44px" },
          maxWidth: { xs: 20, sm: 40, xl: 80 },
          maxHeight: { xs: 20, sm: 40, xl: 80 },
        },
        bulletsContainerSx: {
          zIndex: 1200,
          display: "flex",
          position: "absolute",
          top: { xs: "90%", sm: "80%", md: "78%", lg: "85%", xl: "80%" },
          gap: "20px",
        },
        nvgBtnPrevIcon: (
          <Box
            sx={{
              transform: "rotate(180deg)",
            }}
          >
            <IconLoader
              iconName="ArrowLeftWhiteIcon"
              sx={{ fontSize: { sm: "40px", lg: "80px" } }}
            />
          </Box>
        ),
        nvgBtnNextIcon: (
          <Box>
            <IconLoader
              iconName="ArrowRightWhiteIcon"
              sx={{ fontSize: { sm: "40px", lg: "80px" } }}
            />
          </Box>
        ),
        bulletsSx: {
          width: { xs: "12px" },
          margin: { sx: "unset", md: "25px 0px 0px 0px" },
        },
      }}
    >
      {[...bannerList, ...bannerVideoList].map((banner, index) => {
        const isVideo = banner.ImageFileURL?.toLowerCase().includes(".mp4");
        const bannerLink = banner.ExternalLink
          ? banner.ExternalLink
          : PagePath.contentDetail + "/" + banner.ContentId;
        return (
          <Box
            key={`banner-home-${banner.ContentId}`}
            sx={{
              position: "relative",
              width: "100%",
              maxHeight: {
                xs: "844px",
                sm: "512px",
                lg: "746px",
                xl: "900px",
              },
              overflow: "hidden",
              img: {
                aspectRatio: { xs: 390 / 844, sm: 834 / 521, lg: 1194 / 746, xl: 16 / 10 },
              },
            }}
          >
            <Link href={bannerLink} target="_blank" rel="noopener noreferrer">
              {isVideo ? (
                <video
                  loop
                  muted
                  autoPlay
                  preload="none"
                  controlsList="nodownload"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                >
                  <source src={banner.ImageFileURL!} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <Image
                  src={banner.ImageFileURL || "'"}
                  alt="Toyota Sure Banner"
                  width={isLessThanSm ? 390 : 1440}
                  height={isLessThanSm ? 844 : 900}
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                  priority={index <= 0 ? true : false}
                  loading={index <= 0 ? undefined : "lazy"}
                />
              )}
            </Link>
          </Box>
        );
      })}
    </CarouselNavigateFraction>
  );
};

export default BannerHome;
