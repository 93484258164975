import VehicleSuggestionCard from "@/components/Card/VehicleSuggestionCard";
import CarouselNavigateFraction, {
  ICarouselNavigateFractionRefProps,
} from "@/components/CarouselCommon/CarouselNavigateFraction";
import { useWishlist } from "@/contexts/useWishlist";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { VehicleCard } from "@/libs/types/vehicle.type";
import { swiperOption } from "@/modules/dealer-detail/style";
import color from "@/styles/color";
import { forwardRef } from "react";

type RenderCarouselSuggestProps = {
  items: VehicleCard[];
  actionName: string;
  carouselName: string;
};

const RenderCarouselSuggest = forwardRef<
  ICarouselNavigateFractionRefProps,
  RenderCarouselSuggestProps
>(({ items, actionName, carouselName }: RenderCarouselSuggestProps, ref) => {
  const { isLessThanSm, isLessThanLg } = useReadScreen();
  const { setVehicleByID, getVehiclesDict } = useWishlist();
  const vehicleDict = getVehiclesDict();

  const slidesPerGroup = () => {
    if (isLessThanLg) return 3;

    return 4;
  };

  return (
    <CarouselNavigateFraction
      ref={ref}
      name={carouselName}
      containerSx={{
        position: "relative",
        maxWidth: { xs: "100%", xl: "100%" },
        height: "auto",
        "& .swiper-slide": {
          width: { xs: "unset", md: "unset", xl: "100%" },
        },
        "& .carousel_navigate_fraction_main": {
          "> .swiper-wrapper": {
            marginBottom: { xs: "12px", sm: "unset", lg: "6px" },
          },
        },
      }}
      navigateType={isLessThanSm ? ["nvg-bullets"] : ["nvg-btn-center"]}
      swiperOption={{
        ...swiperOption,
        slidesPerGroup: slidesPerGroup(),
        breakpoints: {
          600: {
            slidesPerView: 3,
            spaceBetween: 8,
          },
          1194: {
            slidesPerView: 4,
            spaceBetween: 8,
          },
        },
      }}
      options={{
        bulletNonActiveColor: color.gray170,
        prevBtnSx: {
          top: "50%",
          left: { xs: "-30px", xl: "-20px" },
        },
        nextBtnSx: {
          top: "50%",
          right: { xs: "-30px", xl: "-20px" },
        },
      }}
    >
      {Boolean(items && items?.length > 0)
        ? items.map((item, index) => (
            <VehicleSuggestionCard
              key={`${actionName}-${index + 1}`}
              isWishlist={vehicleDict[item.VehicleMasterId]}
              onClickWishlist={setVehicleByID}
              data={item}
            />
          ))
        : []}
    </CarouselNavigateFraction>
  );
});

RenderCarouselSuggest.displayName = "RenderCarouselSuggest";

export default RenderCarouselSuggest;
