"use client";

import { FC, useMemo } from "react";
import { Box, Container } from "@mui/material";

import FAQHome from "@/components/FAQSection/FAQHome";
import { ContentManagementListResponse } from "@/libs/types/content-management.type";
import color from "@/styles/color";
import { useQuery } from "@tanstack/react-query";
import { HomeQueryKeyEnum } from "@/libs/enum/home-query-key.enum";
import { ContentManagementQueryMap } from "@/libs/types/content-management-query.type";
import { ContentManagementQueryKeyEnum } from "@/libs/enum/content-management-query-key.enum";
import { ContentManagementServices } from "@/libs/services/banners/banners.service";

const Faq: FC = () => {
  const params = new ContentManagementQueryMap();
  params.set(ContentManagementQueryKeyEnum.FAQ, {
    Limit: 5,
    Length: 550,
    SortBy: "CreateDate",
    SortDirection: "DESC",
  });

  const { data } = useQuery<ContentManagementListResponse>({
    queryKey: [HomeQueryKeyEnum.HOME_FAQ],
    queryFn: () => ContentManagementServices.getContentManagement(params).then((res) => res),
  });

  const faqList = useMemo(() => {
    return data ? data.ContentManagement : [];
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: color.black100,
        position: "relative",
      }}
    >
      <Container
        maxWidth="2xl"
        sx={{
          width: "100%",
          padding: { xs: "40px 24px", sm: "40px", xl: "60px 100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FAQHome items={faqList} />
      </Container>
    </Box>
  );
};

export default Faq;
