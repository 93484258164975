"use client";
import color from "@/styles/color";
import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import DealerLocationMockImg from "@/assets/image/dealer-location-mock.png";
import ButtonCustom from "../Button/ButtonCustom";
import ChipCustom from "../Chip/ChipCustom";
import LineLogoImg from "@/assets/image/LINE_logo.webp";
import MessengerLogoImg from "@/assets/image/messenger-logo.webp";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import ImageCustom from "../Image/ImageCustom";
import { useDealerStore } from "@/contexts/useDealer";
import { DealerServices } from "@/libs/services/dealer";
import { useQuery } from "@tanstack/react-query";
import IconLoader from "../IconLoader";
import Link from "next/link";
import { useTranslation } from "@/i18n/client";
import AccordionDealerOpenDay from "../Accordion/AccordionDealerOpenDay";
import { DealerListResponse } from "@/libs/types/dealer.type";
import { IGeo } from "@/contexts/useMyLocation";
interface IDealerLocationDetailCardProps {
  branchId: number;
  distance: number;
  callback?: (geo: IGeo) => Promise<void>;
}

export const openTimeMap: Record<"open" | "close", { color: string; bgcolor: string }> = {
  close: {
    color: color.red,
    bgcolor: color.pink100,
  },
  open: {
    color: color.green200,
    bgcolor: color.green100,
  },
};

export const FORMAT_DATE_OPEN_TIME = "HH:mm A";
dayjs.extend(isBetween);

function DealerLocationDetailCard({
  branchId,
  distance = 0,
  callback,
}: IDealerLocationDetailCardProps) {
  const { t } = useTranslation();
  const { setDealerInfo } = useDealerStore();

  const { data: dealerDetail, isPending } = useQuery<DealerListResponse>({
    queryKey: ["DEALER_LOCATION_DETAIL", { BranchId: `[${branchId.toString()}]` }],
    queryFn: () => DealerServices.getDealerList({ BranchId: `[${branchId.toString()}]` }),
    enabled: !!branchId,
  });

  useEffect(() => {
    const dealer = dealerDetail?.DealerInfo?.[0] || undefined;
    if (dealer && callback) {
      (async () => {
        await callback({ lat: +dealer.Latitude, lng: +dealer.Longtitude });
      })();
    }
  }, [dealerDetail?.DealerInfo]);

  const dealer = useMemo(() => {
    return dealerDetail?.DealerInfo?.[0] || undefined;
  }, [dealerDetail]);

  if (isPending || !dealer)
    return (
      <Skeleton
        data-testid="dealer-location-skeleton"
        sx={{
          width: { xs: "342px", sm: "500px", xl: "555px" },
          height: { xs: "555px", sm: "374px", xl: "500" },
          position: "relative",
        }}
      />
    );

  return (
    <>
      {dealer ? (
        <Box
          sx={{
            width: { xs: "342px", sm: "500px", xl: "555px" },
            padding: { xs: "16px" },
            borderRadius: "16px",
            border: { xs: "unset", sm: `1px solid ${color.gray160}` },
            display: "flex",
            flexDirection: { xs: "column" },
            position: "relative",
            gap: { xs: "16px" },
            boxShadow: { xs: "0px 4px 4px 0px #0000001A", sm: "unset" },
            background: color.white,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "16px" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                minWidth: { xs: "310px", sm: "185px", xl: "240px" },
                height: { xs: "231px", sm: "222px", xl: "220px" },
                width: { xs: "unset", xl: "100%" },
              }}
            >
              <ImageCustom
                imageSrc={dealer?.ShowRoomImageURL || DealerLocationMockImg.src}
                imageAlt="Dealer Thumbnail"
                sx={{
                  minWidth: "100%",
                  img: {
                    objectFit: "cover",
                    borderRadius: "16px",
                    width: {
                      xs: "310px !important",
                      sm: "185px !important",
                      xl: "240px !important",
                    },
                    height: {
                      xs: "231px !important",
                      sm: "222px !important",
                      xl: "220px !important",
                    },
                  },
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  bottom: { xs: "16px", sm: "12px", xl: "15px" },
                  transform: {
                    xs: "translate(-50%, 0)",
                    xl: "translate(-50%, 0)",
                  },
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: { sm: "0px 2px", xl: "unset" },
                }}
              >
                <ButtonCustom
                  label={t("Home.dealer.direction")}
                  onClick={() => {
                    return window.open(dealer?.GoogleMapId, "_blank");
                  }}
                  sx={{
                    background: color.white,
                    fontSize: { xs: "14px" },
                    lineHeight: { xs: "23px" },
                    color: color.gray400,
                    width: "max-content",
                    padding: { xs: "16px 24px", sm: "16px 12px", xl: "16px 24px" },
                    ":hover": {
                      background: color.white,
                      color: color.gray400,
                      boxShadow: "unset",
                      opacity: 0.9,
                    },
                    "& .MuiButton-startIcon": {
                      marginRight: { xs: "8px", sm: "4px", xl: "8px" },
                      marginLeft: { xs: "-4px", sm: "0px", xl: "-4px" },
                    },
                  }}
                  startIcon={
                    <IconLoader
                      iconName="PinGoogleIcon"
                      sx={{ fontSize: { xs: "16px", sm: "14px", xl: "16px" } }}
                    />
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "14px" },
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "16px" },
                  lineHeight: { xs: "24.5px" },
                  color: color.gray400,
                }}
              >
                {dealer?.DealerDisplayName || "-"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  gap: "8px",
                }}
              >
                <IconLoader iconName="LocationGradientIcon" sx={{ fontSize: "20px" }} />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: "14px" },
                    lineHeight: { xs: "19px" },
                    color: color.gray400,
                  }}
                >
                  {dealer?.Address || "-"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: { xs: "center", sm: "start" },
                  gap: "8px",
                }}
              >
                <IconLoader iconName="DistanceLocaltionGradientIcon" sx={{ fontSize: "20px" }} />
                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: "12px", sm: "15px" },
                    lineHeight: { xs: "16px", sm: "20px" },
                    color: color.gray400,
                  }}
                >
                  ห่างจากคุณ {distance} กิโลเมตร
                </Typography>
              </Box>

              <Box
                component={"div"}
                sx={{
                  display: { xs: "flex" },
                  flexWrap: "wrap",
                  columnGap: "8px",
                  rowGap: { xs: "8px", sm: "4px" },
                }}
              >
                <Box
                  component={"div"}
                  sx={{
                    display: { xs: "flex" },
                    columnGap: "8px",
                    rowGap: { xs: "8px", sm: "4px" },
                    width: { xs: "100%", sm: "100%", xl: "100%" },
                  }}
                >
                  <Box
                    component={"div"}
                    sx={{
                      order: 1,
                      width: "100%",
                    }}
                  >
                    <ChipCustom
                      label="Line"
                      icon={
                        <ImageCustom
                          imageSrc={LineLogoImg.src}
                          imageAlt="Line Logo"
                          sx={{
                            img: {
                              borderRadius: "100%",
                              objectFit: "contain",
                              maxWidth: "unset",
                              width: {
                                xs: "28px !important",
                              },
                              height: {
                                xs: "28px !important",
                              },
                            },
                          }}
                        />
                      }
                      containerStyles={{
                        width: { xs: "100%" },
                        height: { xs: "40px" },
                        justifyContent: "center",
                        border: `1px solid ${color.gray700}`,
                        cursor: "pointer",
                      }}
                      labelStyles={{
                        color: color.black,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: { xs: "14px" },
                        lineHeight: { xs: "23px" },
                      }}
                      onClick={() => setDealerInfo("line", NaN, Number(dealer?.DealerId))}
                    />
                  </Box>
                  <Box
                    component={"div"}
                    sx={{
                      order: 1,
                      width: "100%",
                    }}
                  >
                    <ChipCustom
                      label="Chat"
                      icon={
                        <ImageCustom
                          imageSrc={MessengerLogoImg.src}
                          imageAlt="Messenger Logo"
                          sx={{
                            img: {
                              objectFit: "contain",
                              maxWidth: "unset",
                              width: {
                                xs: "28px !important",
                              },
                              height: {
                                xs: "28px !important",
                              },
                            },
                          }}
                        />
                      }
                      containerStyles={{
                        width: { xs: "100%" },
                        height: { xs: "40px" },
                        justifyContent: "center",
                        border: `1px solid ${color.gray700}`,
                        cursor: "pointer",
                      }}
                      labelStyles={{
                        color: color.black,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: { xs: "14px" },
                        lineHeight: { xs: "23px" },
                      }}
                      onClick={() => setDealerInfo("facebook", NaN, Number(dealer?.DealerId))}
                    />
                  </Box>
                </Box>
                <Box
                  component={"div"}
                  sx={{
                    order: 1,
                    width: "100%",
                    flex: "1 1 auto",
                  }}
                >
                  <ChipCustom
                    label="โทรสอบถาม"
                    icon={
                      <IconLoader
                        iconName="TelephoneIcon"
                        sx={{
                          fontSize: { xs: "24px" },
                          color: { xs: color.gray700 },
                        }}
                      />
                    }
                    containerStyles={{
                      width: { xs: "100%" },
                      height: { xs: "40px" },
                      justifyContent: "center",
                      border: `1px solid ${color.gray700}`,
                      cursor: "pointer",
                    }}
                    labelStyles={{
                      color: color.black,
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: { xs: "14px" },
                      lineHeight: { xs: "23px" },
                    }}
                    onClick={() => setDealerInfo("telephone", NaN, Number(dealer?.DealerId))}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: { xs: "8px" },
              borderRadius: "8px",
              border: { xs: "unset", sm: `1px solid ${color.gray160}` },
              display: "flex",
              flexDirection: { xs: "column" },
              position: "relative",
              boxShadow: { xs: "0px 4px 4px 0px #0000001A", sm: "unset" },
              background: color.white,
              "& .MuiAccordionSummary-expandIconWrapper": {
                flex: 1,
                justifyContent: "flex-end",
              },
            }}
          >
            <AccordionDealerOpenDay
              showIcon
              showScroll
              data={dealer}
              sx={{
                gridLeft: {
                  props: { xs: 5, sm: 6, lg: 4 },
                  defaultComponent: "div",
                },
                gridRight: {
                  props: { xs: 7, sm: 6, lg: 8 },
                  defaultComponent: "div",
                },
              }}
            />
          </Box>
          <Link
            rel="noopener noreferrer"
            href={
              "/dealerdetails/dealerdetails/" + dealer?.DealerId + "/branch/" + dealer?.BranchId
            }
          >
            <ButtonCustom
              label={t("Home.dealer.total-vehicles-in-dealer").replace(
                "{{count}}",
                dealer ? `${dealer.Count}` : "",
              )}
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
                height: "44px",
              }}
              startIcon={
                <IconLoader iconName="CarForIcon" sx={{ fontSize: "24px", color: color.white }} />
              }
            />
          </Link>
        </Box>
      ) : null}
    </>
  );
}

export default DealerLocationDetailCard;
