import ButtonCustom from "@/components/Button/ButtonCustom";
import color from "@/styles/color";
import { Email } from "@mui/icons-material";
import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "@/i18n/client";
import HumanSorryIconSvg from "@/assets/image/sorry.webp";
import IconLoader from "@/components/IconLoader";

const ArticleBtnActiveInPageNotFound: SxProps = {
  fontWeight: { xs: 500, sm: 500, xl: 500 },
  height: { xs: "40px", xl: "40px" },
  width: { xs: "100%", sm: "fit-content" },
  padding: { xs: "24px", sm: "24px", xl: "0px 24px" },
  fontSize: { xs: "10px", sm: "14px", xl: "16px" },
  lineHeight: { xs: "23px", sm: "23px", xl: "23px" },
  background: color.redGradient,
  color: color.white100,
  ":hover": {
    background: color.redGradient,
    color: color.white100,
    boxShadow: "unset",
    opacity: 0.7,
  },
};

interface ArticleNotFoundProps {
  textToSearchNotFound?: string;
}

export default function ArticleNotFound({ textToSearchNotFound }: ArticleNotFoundProps) {
  const { t } = useTranslation();
  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: { xs: "20px", sm: "24px", lg: "34px" },
          paddingBottom: { xs: "16px", sm: "24px", lg: "34px" },
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 500, sm: 500, xl: 500 },
            fontSize: { xs: "14px", sm: "14px", xl: "24px" },
            lineHeight: { xs: "21.15px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
          }}
        >
          {t("Article.SearchTerm")} &ldquo;{textToSearchNotFound}&ldquo;
        </Typography>

        <Typography
          sx={{
            fontWeight: { xs: 500, sm: 500, xl: 500 },
            fontSize: { xs: "12px", sm: "12px", xl: "24px" },
            lineHeight: { xs: "18.13px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
          }}
        >
          {t("Article.SearchNotFound")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: { xs: "100%", sm: "100%", xl: "100%" },
          padding: { xs: "40px", sm: "40px", xl: "40px" },
          backgroundColor: "#F8F8F8",
          borderRadius: { xs: "24px", sm: "24px", xl: "24px" },
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: {
            xs: "16px",
            sm: "24px 8px !important",
            xl: "0px 16px !important",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 700, sm: 500, xl: 700 },
            fontSize: { xs: "16px", sm: "24px", xl: "20px" },
            lineHeight: { xs: "24.18px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
            width: "100%",
          }}
        >
          {t("Article.ContentWordInSearchNotFound")}
        </Typography>

        <Box
          component="img"
          sx={{
            width: { xs: "150px", sm: "150px", xl: "200px" },
            height: { xs: "150px", sm: "150px", xl: "200px" },
          }}
          alt="car_leasing svg"
          src={HumanSorryIconSvg.src}
        />

        <Typography
          sx={{
            fontWeight: { xs: 500, sm: 500, xl: 700 },
            fontSize: { xs: "14px", sm: "14px", xl: "20px" },
            lineHeight: { xs: "21.15px", sm: "36px", xl: "54px" },
            textAlign: "center",
            color: color.black,
            width: "100%",
          }}
        >
          {t("Article.CallCenterText")}
        </Typography>

        <ButtonCustom
          startIcon={<IconLoader iconName="Telephone2Icon" />}
          label={t("Article.Contact")}
          href={"tel:+1486"}
          sx={{
            ...ArticleBtnActiveInPageNotFound,
          }}
        />

        <ButtonCustom
          startIcon={<Email />}
          label="contactcenter@toyota.co.th"
          href={"mailto:contactcenter@toyota.co.th"}
          sx={{
            ...ArticleBtnActiveInPageNotFound,
          }}
        />

        {/* <ButtonCustom
          startIcon={<IconLoader iconName="LineTangularIcon" />}
          label="Add Line"
          sx={{
            ...ArticleBtnActiveInPageNotFound,
          }}
        /> */}
      </Box>
    </Box>
  );
}
